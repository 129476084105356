<template>
  <div>
    <div class="row items-start q-col-gutter-sm">
      <div id="OverviewGrid" class="col-12" style="margin-top: 5px">
        <!--heading-->
        <div class="grid">
          <div class="row items-center1 heading">
            <div class="col row items-center">
              <div
                class="text-base col q-pa-sm font-bold text-uppercase q-pa-md"
              >
                Uploaded Files
              </div>
              <div v-if="!dashboardView" class="col-auto q-pa-sm">
                <BaseActionButton
                  icon="mdi-pin"
                  :color="dashboard ? 'secondary' : 'gray'"
                  no-border
                  is-flat
                  size="20px"
                  rotate="rotate-45"
                  @click="
                    dashboard ? $emit('isDelete') : $emit('pinRecentActivity')
                  "
                />
              </div>
            </div>
          </div>
          <!--heading-->
          <!--Grid 1-->
          <div>
            <div class="row items-center1">
              <div class="col row items-center">
                <BaseIcon
                  name="mdi-file-edit-outline"
                  size="20px"
                  class="icon icons"
                  color="white"
                  style="background-color: lightgray"
                />
                <div class="col row items-center">
                  <div class="text-base col q-pa-sm font-bold text-uppercase">
                    Manual Indexed
                    <div class="text-base col textcontent text-uppercase">
                      {{ credits.MANUAL || 0 }} file
                    </div>
                  </div>
                </div>
              </div>
              <div class="col row items-center">
                <BaseIcon
                  name="mdi-robot"
                  size="20px"
                  class="icon icons"
                  color="white"
                  style="background-color: rgb(207 172 239)"
                />
                <div class="col row items-center">
                  <div class="text-base col q-pa-sm font-bold text-uppercase">
                    Advanced ocr
                    <div class="text-base col text-uppercase textcontent">
                      {{ credits.ADVANCED || 0 }} file
                    </div>
                  </div>
                </div>
              </div>
              <div class="col row items-center">
                <BaseIcon
                  name="mdi-file-image"
                  size="20px"
                  class="icon icons"
                  color="white"
                  style="background-color: rgb(156 223 229)"
                />
                <div class="col row items-center">
                  <div class="text-base col q-pa-sm font-bold text-uppercase">
                    standard ocr
                    <div class="text-base col text-uppercase textcontent">
                      {{ credits.STANDARD || 0 }} file
                    </div>
                  </div>
                </div>
              </div>
              <div class="col row items-center">
                <BaseIcon
                  name="mdi-wallet"
                  size="21px"
                  class="icon icons"
                  color="white"
                  style="background-color: rgb(190 232 214)"
                />

                <div class="col row items-center">
                  <div class="text-base col q-pa-sm font-bold text-uppercase">
                    balance credits
                    <div class="text-uppercase textcontent">
                      {{ credits.balanceCredit || 0 }} Credits
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--grid 1-->
            <!--grid 2-->
            <div class="row items-center1">
              <div class="col row items-center" style="padding-left: 5px">
                <BaseIcon
                  name="mdi-chart-areaspline"
                  size="20px"
                  class="icon"
                  color="black"
                  @click="$emit('chart')"
                />
                <div
                  class="col row items-center font-bold text-base col q-pa-sm text-uppercase cursor-pointer"
                  @click="$emit('chart')"
                >
                  Show Charts
                  <BaseIcon
                    v-if="chartView"
                    name="eva-arrow-ios-upward-outline"
                    is-flat
                    no-border
                    class="icone q-ml-xs q-mr-xs"
                    color="black"
                  />
                  <BaseIcon
                    v-else
                    name="eva-arrow-ios-downward-outline"
                    size="20px"
                    is-flat
                    no-border
                    class="icone q-ml-xs q-mr-xs"
                    color="black"
                  />
                </div>
              </div>
            </div>
          </div>
          <!--grid 2-->
          <!--graph-->

          <!--graph-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import OverviewReportCharts from "./OverviewHorizondalChart.vue"
// import Charts from "@/views/repositories/views/repository-overview/Charts";
export default {
  name: "DetailsGrid",
  //   components:{
  //     Charts,
  //   },
  props: {
    credits: {
      type: Object,
      required: true,
    },
    chartView: {
      type: Boolean,
      default: false,
    },
    dashboardView: {
      type: Boolean,
      default: false,
    },
    dashboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      calendar: false,
      DateOptions: [
        { first: "Last 30 Days" },
        { first: "Last 2 months" },
        { first: "Last 3 months" },
        { first: "Last 1 year" },
      ],
    };
  },
};
</script>

<style lang="scss">
#OverviewGrid {
  border-radius: 10px;
  background-color: var(--component-bg-color);
  padding: 10px;

  .grid {
    width: 100%;
    border-radius: 5px;
    background-color: var(--component-bg-color);
    border: 1px solid var(--divider-color);
    border-radius: 10px;
    .description {
      color: $primary-11 !important;
    }
    .heading {
      background-color: var(--body-bg-color);
      border-radius: 10px 10px 0px 0px;
    }
    .content {
      background-color: var(--component-bg-color);
      border-bottom: 1px solid var(--divider-color);
    }
  }
  .icons {
    border-bottom: 1px solid var(--divider-color);
    margin: 20px;
    border-radius: 5px;
    padding: 10px;
  }
  .textcontent {
    font-size: smaller;
  }
  .icone {
    cursor: pointer;
  }
  .icone:hover {
    background-color: #e2e8f0 !important;
    border-radius: 50px;
  }
}
</style>
