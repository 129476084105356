<template>
  <div id="tabs" :style="{ color: _color }">
    <!-- ... -->

    <Tab
      v-for="tab in tabs"
      :key="tab.id"
      ref="tab"
      :label="tab.label"
      :color="color"
      :is-full-width="isFullWidth"
      :is-active="tab.value === value"
      @click="handleClick(tab)"
    />

    <!-- ... -->

    <!-- indicator -->

    <div ref="indicator" class="indicator"></div>

    <!-- ... -->
  </div>
</template>

<script>
import Tab from "./Tab.vue";

export default {
  name: "Tabs",

  components: { Tab },

  props: {
    tabs: {
      type: Array,
      required: true,
    },

    value: {
      type: [String, Number],
      required: true,
    },

    color: {
      type: String,
      default: "primary",
    },

    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _color() {
      return `var(--${this.color})`;
    },
  },

  watch: {
    value() {
      this.setIndicatorPosition();
    },
  },

  mounted() {
    this.setIndicatorPosition();
  },

  methods: {
    setIndicatorPosition() {
      const tabIdx = this.tabs.findIndex((tab) => tab.value === this.value);
      const target = this.$refs.tab[tabIdx];

      const left = target.$el.offsetLeft;
      this.$refs.indicator.style.left = `${left}px`;

      const width = target.$el.clientWidth;
      this.$refs.indicator.style.width = `${width}px`;
    },

    handleClick(tab) {
      if (tab.value === this.value) {
        return;
      }

      this.$emit("input", tab.value);
    },
  },
};
</script>

<style lang="scss" scoped>
#tabs {
  display: flex;
  align-items: center;
  position: relative;

  .indicator {
    position: absolute;
    bottom: -0.5px;
    height: 3px;
    background-color: currentColor;
    border-radius: 2px 2px 0px 0px;
    transition: all 0.25s ease;
  }
}
</style>
