import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";

import Component from "./FieldPlaceholder.vue";

export default Node.create({
  name: "FieldPlaceholder",

  group: "inline",

  inline: true,

  selectable: false,

  atom: true,

  // content: "text*",

  // code: true,

  addAttributes() {
    return {
      fieldType: {
        type: String,
        required: true,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "FieldPlaceholder",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["FieldPlaceholder", mergeAttributes(HTMLAttributes)];
  },

  // addCommands() {
  //   return {
  //     setParagraph:
  //       () =>
  //       ({ commands }) => {
  //         return commands.setNode(this.name);
  //       },
  //   };
  // },

  addNodeView() {
    return VueNodeViewRenderer(Component);
  },
});
