<template>
  <div id="toolbar">
    <!-- add field -->

    <AddField :form-columns="formColumns" @click="addField" />

    <!-- ... -->

    <!-- heading -->
    <template v-if="hideToolBar">
      <Heading @set="setHeading" @unset="unsetHeading" />

      <!-- ... -->

      <!-- alignment -->

      <Alignment @toggle="toggleAlignment" />

      <!-- ... -->

      <!-- bold -->

      <BaseActionButton
        v-tooltip.top="'bold'"
        is-flat
        icon="mdi-format-bold"
        @click="toggleBold"
      />

      <!-- ... -->

      <!-- italic -->

      <BaseActionButton
        v-tooltip.top="'italic'"
        is-flat
        icon="mdi-format-italic"
        @click="toggleItalic"
      />

      <!-- ... -->

      <!-- underline -->

      <BaseActionButton
        v-tooltip.top="'underline'"
        is-flat
        icon="mdi-format-underline"
        @click="toggleUnderline"
      />

      <!-- ... -->

      <!-- list -->

      <List @bullet="toggleBulletList" @number="toggleNumberList" />

      <!-- ... -->

      <!-- indent -->

      <Indent @increase="increaseIndent" @decrease="decreaseIndent" />

      <!-- ... -->

      <!-- text color -->

      <TextColor @set="setTextColor" @unset="unsetTextColor" />

      <!-- ... -->

      <!-- background color -->

      <BackgroundColor @set="setBgColor" @unset="unsetBgColor" />

      <!-- ... -->

      <!-- undo -->

      <BaseActionButton
        v-tooltip.top="'undo'"
        is-flat
        icon="mdi-undo"
        @click="undo"
      />

      <!-- ... -->

      <!-- redo -->

      <BaseActionButton
        v-tooltip.top="'redo'"
        is-flat
        icon="mdi-redo"
        @click="redo"
      />

      <!-- ... -->

      <!-- link -->

      <BaseActionButton
        v-tooltip.top="'link'"
        is-flat
        icon="eva-link-2-outline"
        @click="setLink"
      />
    </template>
    <!-- ... -->
  </div>
</template>

<script>
import Heading from "./components/Heading.vue";
import Alignment from "./components/Alignment.vue";
import List from "./components/List.vue";
import Indent from "./components/Indent.vue";
import TextColor from "./components/TextColor.vue";
import BackgroundColor from "./components/BackgroundColor.vue";
import AddField from "./components/AddField.vue";

export default {
  name: "Toolbar",

  components: {
    Heading,
    Alignment,
    List,
    Indent,
    TextColor,
    BackgroundColor,
    AddField,
  },

  props: {
    hideToolBar: {
      type: Boolean,
      default: false,
    },

    formColumns: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    setHeading(level) {
      this.$emit("set-heading", level);
    },

    unsetHeading() {
      this.$emit("unset-heading");
    },

    toggleAlignment(alignment) {
      this.$emit("toggle-alignment", alignment);
    },

    toggleBold() {
      this.$emit("toggle-bold");
    },

    toggleItalic() {
      this.$emit("toggle-italic");
    },

    toggleUnderline() {
      this.$emit("toggle-underline");
    },

    toggleBulletList() {
      this.$emit("toggle-bullet-list");
    },

    toggleNumberList() {
      this.$emit("toggle-number-list");
    },

    increaseIndent() {
      this.$emit("increase-indent");
    },

    decreaseIndent() {
      this.$emit("decrease-indent");
    },

    setTextColor(color) {
      this.$emit("set-text-color", color);
    },

    unsetTextColor() {
      this.$emit("unset-text-color");
    },

    setBgColor(color) {
      this.$emit("set-bg-color", color);
    },

    unsetBgColor() {
      this.$emit("unset-bg-color");
    },

    undo() {
      this.$emit("undo");
    },

    redo() {
      this.$emit("redo");
    },

    setLink() {
      this.$emit("set-link");
    },

    addField(fieldType) {
      this.$emit("add-field", fieldType);
    },
  },
};
</script>

<style lang="scss" scoped>
#toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--divider-color);
}
</style>
