<template>
  <div>
    <template v-if="id">
      <div
        class="row items-start q-col-gutter-sm"
        style="width: 100%; margin-top: 5px"
      >
        <div id="detailsGrid" class="col-6" style="width: 50%; margin-left: 0%">
          <div class="grid">
            <!--heading-->
            <div class="row items-center1 heading">
              <div class="col row items-center">
                <div class="text-base col q-pa-sm font-bold text-uppercase">
                  Total Files Uploaded
                </div>
              </div>
              <div
                class="row items-center"
                style="cursor: pointer"
                @click="uploadFiles"
              >
                <BaseActionButton
                  v-if="id"
                  v-tooltip.top="'Upload File'"
                  size="20px"
                  icon="mdi-plus"
                  class="icons"
                /><span class="text-base col q-pa-xs font-bold text-uppercase"
                  >Upload Files</span
                >
              </div>
              <div>
                <BaseActionButton
                  v-if="dashboard"
                  icon="mdi-pin"
                  color="secondary"
                  no-border
                  is-flat
                  size="20px"
                  rotate="rotate-45"
                  @click="$emit('isDelete')"
                />
                <BaseActionButton
                  v-else
                  icon="mdi-pin"
                  color="gray"
                  no-border
                  is-flat
                  size="20px"
                  rotate="rotate-45"
                  @click="$emit('issavesheet')"
                />
              </div>
            </div>

            <!--heading-->
            <!--Grid 1-->

            <div class="row items-center1 content">
              <div class="col row items-center">
                <div class="text-base col q-pa-sm font-bold text-uppercase">
                  <apexchart
                    type="bar"
                    height="150"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </div>
              </div>
            </div>

            <div class="row items-center1 content" style="border-radius: 10px">
              <div
                class="text-base col q-pa-sm font-bold text-uppercase"
                style="
                  color: #00bcd4;
                  border-right: 1px solid var(--divider-color);
                  height: 73px;
                "
              >
                Standard
                <div
                  class="text-base col q-pa-sm font-bold text-uppercase text-black"
                >
                  {{ credits.STANDARD || 0 }}
                </div>
              </div>
              <div
                class="text-base col q-pa-sm font-bold text-uppercase"
                style="color: #643094"
              >
                Advanced
                <div
                  class="text-base col q-pa-sm font-bold text-uppercase text-black"
                >
                  {{ credits.ADVANCED || 0 }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--grid 1-->
        <div
          id="detailsGrid"
          class="col-6"
          style="width: 49.6%; margin-left: 5px"
        >
          <div class="grid">
            <div
              class="col row items-center text-base col q-pa-sm font-bold text-uppercase heading"
              style="width: 100%"
            >
              <div class="col">Recent Activity</div>
              <div class="col-auto">
                <BaseActionButton
                  v-if="recent"
                  icon="mdi-pin"
                  color="secondary"
                  no-border
                  is-flat
                  size="20px"
                  rotate="rotate-45"
                  @click="$emit('isDeleteRecent')"
                />
                <BaseActionButton
                  v-else
                  icon="mdi-pin"
                  color="gray"
                  no-border
                  is-flat
                  size="20px"
                  rotate="rotate-45"
                  @click="$emit('pinRecentActivity')"
                />
              </div>
            </div>

            <!--Grid 2-->
            <BaseScrollbar height="234px">
              <table style="width: 100%">
                <thead>
                  <tr>
                    <th class="th text-center">Activity On</th>
                    <th class="th text-center" style="width: 20%">Activity</th>
                    <th class="th text-center">Activity By</th>
                    <th
                      class="th text-center"
                      style="width: 30%; border-right: 0px"
                    >
                      File Name
                    </th>
                  </tr>
                </thead>

                <tbody style="width: 100%">
                  <tr
                    v-for="(activityRow, index) in recentActivity"
                    :key="index"
                  >
                    <td class="td">
                      {{ $day.format(activityRow.activityOn) }}
                    </td>
                    <td class="td" style="width: 20%">
                      {{ activityRow.activityName }}
                    </td>
                    <td class="td">
                      {{ activityRow.activityBy || "-" }}
                    </td>

                    <td
                      class="td font-medium ellipsis"
                      style="
                        cursor: pointer;
                        text-decoration: underline;
                        max-width: 200px;
                        border-right: 0px;
                      "
                      @click="openFile(activityRow.itemId)"
                    >
                      {{ activityRow.ifileName }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </BaseScrollbar>
            <!--grid 2-->
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div id="detailsGrid" class="col-12" style="width: 100%; margin-left: 0%">
        <div class="grid">
          <!--heading-->
          <div class="row items-center1 heading">
            <div class="col row items-center">
              <div class="text-base col q-pa-sm font-bold text-uppercase">
                Total Files Uploaded
              </div>
            </div>
            <div class="row items-center" style="margin-right: 5px">
              <div>
                <BaseActionButton
                  v-if="id"
                  v-tooltip.top="'Upload File'"
                  size="15px"
                  icon="mdi-plus"
                  class="icons"
                  @click="uploadFiles"
                />
              </div>
              <div>
                <BaseActionButton
                  v-if="dashboard"
                  icon="mdi-pin"
                  color="secondary"
                  no-border
                  is-flat
                  size="20px"
                  rotate="rotate-45"
                  @click="$emit('isDelete')"
                />

                <BaseActionButton
                  v-else
                  icon="mdi-pin"
                  color="gray"
                  no-border
                  is-flat
                  size="20px"
                  rotate="rotate-45"
                  @click="$emit('issavesheet')"
                />
              </div>
            </div>
          </div>
          <!--heading-->
          <!--Grid 1-->

          <div class="row items-center1 content">
            <div class="col row items-center">
              <div class="text-base col q-pa-sm font-bold text-uppercase">
                <apexchart
                  type="bar"
                  height="150"
                  width="100%"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </div>
          </div>

          <div class="row items-center1 content" style="border-radius: 10px">
            <div
              class="text-base col q-pa-sm font-bold text-uppercase"
              style="
                color: rgb(156 223 229);
                border-right: 1px solid var(--divider-color);
                height: 73px;
              "
            >
              Standard
              <div
                class="text-base col q-pa-sm font-bold text-uppercase text-black"
              >
                {{ credits.STANDARD || 0 }}
              </div>
            </div>
            <div
              class="text-base col q-pa-sm font-bold text-uppercase"
              style="color: rgb(207 172 239)"
            >
              Advanced
              <div
                class="text-base col q-pa-sm font-bold text-uppercase text-black"
              >
                {{ credits.ADVANCED || 0 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <FileSheet
      v-model="isFileSheetVisible"
      :repository="true"
      :file="selectedFile"
      :repository-field="[]"
      :options="{}"
    />
  </div>
</template>
<script>
// import OverviewReportCharts from "./OverviewHorizondalChart.vue"
import FileSheet from "@/views/repositories/views/repository-browse-and-trash/components/FileSheet.vue";
export default {
  name: "DetailsGrid",
  components: {
    FileSheet,
  },
  props: {
    credits: {
      type: Object,
      required: true,
    },
    workspaceList: {
      type: Array,
      default: () => [],
    },
    // data: {
    //   type: Array,
    //   required: true,
    // },
    id: {
      type: Number,
      default: 0,
    },
    workspaceId: {
      type: Number,
      default: 0,
    },
    recentActivity: {
      type: Array,
      default: () => [],
    },
    dashboard: {
      type: Boolean,
      default: false,
    },
    recent: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        colors: ["#d4a4dd", "#9cf1f7"],
        stroke: {
          width: 1,
          colors: ["#d4a4dd", "#9cf1f7"],
        },
        title: {
          text: "",
        },
        xaxis: {
          categories: ["Total Files"],
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " " + "files";
            },
          },
        },
        fill: {
          opacity: 1,
          colors: ["#d4a4dd", "#9cf1f7"],
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      repositoryId: 0,
      selectedFile: {},
      isFileSheetVisible: false,
    };
  },
  watch: {
    credits: {
      deep: true,
      handler() {
        if (this.credits) {
          this.series = [];
          this.series.push({
            name: "Advanced",
            data: [this.credits.ADVANCED || 0],
          });
          this.series.push({
            name: "Standard",
            data: [this.credits.STANDARD || 0],
          });
        }
      },
    },
  },
  methods: {
    uploadFiles() {
      let workspaceId = this.workspaceId;
      let repositoryId = this.id;
      this.$router.push({
        name: "uploadAndIndex",
        query: { workspaceId, repositoryId },
      });
    },
    openFile(itemId) {
      let selected = this.recentActivity.find((file) => file.itemId === itemId);
      this.selectedFile = {
        name: selected.ifileName,
        createdAt: this.$day.format(selected.activityOn),
        createdBy: selected.activityBy,
        repositoryId: this.id,
        id: selected.itemId,
        size: "",
      };
      this.isFileSheetVisible = true;
    },
  },
};
</script>

<style lang="scss">
#detailsGrid {
  border-radius: 10px;
  background-color: var(--component-bg-color);
  padding: 10px;
  .grid {
    width: 100%;
    border: 1px solid var(--divider-color);
    border-radius: 10px;
    height: 296px;
    .description {
      color: $primary-11 !important;
    }
    .heading {
      background-color: var(--body-bg-color);
      border-radius: 10px 10px 0 0;
      border-bottom: 1px solid var(--divider-color);
      padding: 10px;
    }
    .content {
      border-bottom: 1px solid var(--divider-color);
      height: 162px;
    }
    .icons {
      height: 65%;
      width: 16%;
      background-color: #643094 !important;
    }
    .fileName:hover {
      color: blue;
    }
  }
  .th {
    color: #00bcd4;
    border-right: 1px solid var(--divider-color);
    border-bottom: 1px solid var(--divider-color);
    width: 25%;
    padding: 8px;
  }
  .td {
    border-right: 1px solid var(--divider-color);
    width: 25%;
    border-bottom: 1px solid var(--divider-color);
    padding: 8px;
  }

  .ellipsis:hover {
    word-wrap: break-word;
    white-space: initial;
    color: #00bcd4;
    overflow: visible;
  }
}
</style>
