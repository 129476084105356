<template>
  <div id="history">
    <template v-if="history.length">
      <BaseScrollbar height="calc(100vh - 140px)">
        <div v-for="item in history" :key="item.id" class="entry">
          <div class="header">
            {{ item.activity }}
          </div>

          <div class="content">
            <div class="changes-meta">
              <div>{{ item.activityBy }}</div>
              <BaseIcon name="mdi-circle-small" class="q-mx-xs" />
              <div>{{ item.activityOn }}</div>
            </div>
          </div>
        </div>
      </BaseScrollbar>
    </template>

    <div v-else class="no-history">No data found</div>
  </div>
</template>

<script>
export default {
  name: "DocumentHistory",

  props: {
    history: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {};
  },

  created() {
    this.$emit("get");
  },
};
</script>

<style lang="scss" scoped>
#history {
  padding: 8px;

  .no-history {
    font-weight: 500;
    padding: 16px 0px;
    text-align: center;
  }

  .entry {
    padding-left: 24px;
    padding-bottom: 24px;
    position: relative;

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      left: 0px;
      top: 6px;
      background-color: var(--secondary);
      border-radius: 100%;
    }

    &:not(:last-child):after {
      content: "";
      width: 2px;
      height: calc(100% - 6px);
      position: absolute;
      left: 3px;
      top: 14px;
      background-color: var(--hover-bg-color);
    }

    .header {
      color: var(--title-1-color);
      font-weight: 600;
      margin-bottom: 4px;
    }

    .content {
      .changes-meta {
        display: flex;
        align-items: center;
        @extend .text-sm;
        color: var(--icon-color);
        margin-bottom: 16px;
      }

      .change {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        margin-left: -6px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
