var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{attrs:{"id":"repository-field"}},[(_vm.showFreeze)?_c('div',{attrs:{"id":"checkbox-field"}},[_c('BaseIcon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Freeze'),expression:"'Freeze'",modifiers:{"top":true}}],staticClass:"q-mr-sm",attrs:{"name":_vm.freezed ? 'eva-checkmark-circle-2' : 'eva-radio-button-off-outline',"color":"secondary"},on:{"click":_vm.handleFreeze},model:{value:(_vm.freezed),callback:function ($$v) {_vm.freezed=$$v},expression:"freezed"}})],1):_vm._e(),(_vm.field.dataType === 'SHORT_TEXT' || _vm.field.dataType === 'BARCODE')?_c('ValidationProvider',{staticClass:"full-width",attrs:{"name":_vm.field.name,"rules":{ required: _vm.field.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextField',{attrs:{"value":_vm.value,"is-mandatory":_vm.field.isMandatory,"label":_vm.label || _vm.field.name,"error":errors[0],"is-readonly":_vm.readOnly},on:{"input":_vm.handleInput}})]}}],null,false,2138129544)}):_vm._e(),(_vm.field.dataType === 'LONG_TEXT')?_c('ValidationProvider',{staticClass:"full-width",attrs:{"name":_vm.field.name,"rules":{ required: _vm.field.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextAreaField',{attrs:{"value":_vm.value,"is-mandatory":_vm.field.isMandatory,"label":_vm.label || _vm.field.name,"error":errors[0],"is-readonly":_vm.readOnly},on:{"input":_vm.handleInput}})]}}],null,false,3356649023)}):_vm._e(),(_vm.field.dataType === 'NUMBER')?_c('ValidationProvider',{staticClass:"full-width",attrs:{"name":_vm.field.name,"rules":{
        required: _vm.field.isMandatory,
        integer: !_vm.field.options.allowDecimals,
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('NumberField',{attrs:{"value":_vm.value,"is-mandatory":_vm.field.isMandatory,"label":_vm.label || _vm.field.name,"error":errors[0],"is-readonly":_vm.readOnly},on:{"input":_vm.handleInput}})]}}],null,false,3272965974)}):_vm._e(),(_vm.field.dataType === 'DATE')?_c('ValidationProvider',{staticClass:"full-width",attrs:{"name":_vm.field.name,"rules":{ required: _vm.field.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('DateField',{attrs:{"value":_vm.value,"is-mandatory":_vm.field.isMandatory,"label":_vm.label || _vm.field.name,"error":errors[0],"is-readonly":_vm.readOnly},on:{"input":_vm.handleInput}}),(
          _vm.expiry && _vm.value && _vm.value < new Date().toISOString().split('T')[0]
        )?_c('label',{staticClass:"text-red q-mt-sm"},[_vm._v("The chosen date is expired")]):_vm._e()]}}],null,false,1318210805)}):_vm._e(),(_vm.field.dataType === 'TIME')?_c('ValidationProvider',{staticClass:"full-width",attrs:{"name":_vm.field.name,"rules":{ required: _vm.field.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TimeField',{attrs:{"value":_vm.value,"is-mandatory":_vm.field.isMandatory,"label":_vm.label || _vm.field.name,"error":errors[0],"is-readonly":_vm.readOnly},on:{"input":_vm.handleInput}})]}}],null,false,2195704480)}):_vm._e(),(_vm.field.dataType === 'DATE_TIME')?_c('ValidationProvider',{staticClass:"full-width",attrs:{"name":_vm.field.name,"rules":{ required: _vm.field.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('DateTimeField',{attrs:{"value":_vm.value,"is-mandatory":_vm.field.isMandatory,"label":_vm.label || _vm.field.name,"error":errors[0],"is-readonly":_vm.readOnly},on:{"input":_vm.handleInput}})]}}],null,false,1802324180)}):_vm._e(),(
        _vm.field.dataType === 'SINGLE_SELECT' &&
        ['EXISTING'].includes(_vm.field.options.optionsType)
      )?_c('ValidationProvider',{staticClass:"full-width",attrs:{"name":_vm.field.name,"rules":{ required: _vm.field.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('AsyncSelectField',{attrs:{"value":_vm.value,"is-mandatory":_vm.field.isMandatory,"label":_vm.label || _vm.field.name,"api-path":_vm.apiPath,"error":errors[0],"new-option":_vm.field.options.allowToAddNewOptions,"is-readonly":_vm.readOnly},on:{"input":_vm.handleInput}})]}}],null,false,364407705)}):_vm._e(),(
        _vm.field.dataType === 'SINGLE_SELECT' &&
        _vm.field.options.optionsType === 'PREDEFINED'
      )?_c('ValidationProvider',{staticClass:"full-width",attrs:{"name":_vm.field.name,"rules":{ required: _vm.field.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('SelectField',{attrs:{"value":_vm.value,"is-mandatory":_vm.field.isMandatory,"label":_vm.label || _vm.field.name,"error":errors[0],"new-option":_vm.field.options.allowToAddNewOptions,"options":_vm.predefinedSelectOptions,"is-readonly":_vm.readOnly,"page":"repository"},on:{"input":_vm.handleInput}})]}}],null,false,3382750843)}):_vm._e(),(
        _vm.field.dataType === 'SINGLE_SELECT' &&
        ['MASTER'].includes(_vm.field.options.optionsType)
      )?_c('ValidationProvider',{staticClass:"full-width",attrs:{"name":_vm.field.name,"rules":{ required: _vm.field.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('SelectField',{attrs:{"value":_vm.value,"is-mandatory":_vm.field.isMandatory,"label":_vm.label || _vm.field.name,"error":errors[0],"new-option":_vm.field.options.allowToAddNewOptions,"options":_vm.options,"is-readonly":_vm.readOnly,"page":"repository"},on:{"input":_vm.handleInput}})]}}],null,false,768311157)}):_vm._e(),(_vm.field.dataType === 'BOOLEAN')?_c('ValidationProvider',{staticClass:"full-width",attrs:{"name":_vm.field.name,"rules":{ required: _vm.field.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('SingleChoiceField',{attrs:{"value":_vm.value,"is-mandatory":_vm.field.isMandatory,"label":_vm.label || _vm.field.name,"options":_vm.booleanOptions,"error":errors[0],"is-readonly":_vm.readOnly},on:{"input":_vm.handleInput}})]}}],null,false,61245750)}):_vm._e(),(_vm.field.dataType === 'OMR')?_c('ValidationProvider',{staticClass:"full-width",attrs:{"name":_vm.field.name,"rules":{ required: _vm.field.isMandatory }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('SingleChoiceField',{attrs:{"value":_vm.value,"is-mandatory":_vm.field.isMandatory,"label":_vm.label || _vm.field.name,"options":_vm.omrOptions,"error":errors[0],"is-readonly":_vm.readOnly},on:{"input":_vm.handleInput}})]}}],null,false,1875132930)}):_vm._e()],1),_c('div',{staticClass:"row items-center justify-center q-mt-sm"},[(_vm.visiableSyncBtn)?_c('BaseButton',{attrs:{"label":"Sync","color":"secondary"},on:{"click":function($event){return _vm.$emit('saveSync', _vm.field)}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }