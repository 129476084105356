<template>
  <Confirm
    :value="value"
    :icon="header.icon || ''"
    icon-color="red"
    width="450px"
    @input="closeModal"
  >
    <!-- title -->

    <template #title>{{ header?.label }}</template>

    <!-- ... -->

    <template #default>
      <div class="q-py-md">
        <p>
          Are you sure you want to delete this
          <span>file</span>
        </p>

        <p>You can always restore the deleted file from the trash page.</p>
      </div>
    </template>

    <!-- footer -->

    <template #footer>
      <BaseButton
        is-flat
        label="no"
        color="red"
        class="q-mr-sm"
        @click="closeModal"
      />

      <BaseButton label="yes" color="red" @click="deleteFile" />
    </template>

    <!-- ... -->
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";
import { repository } from "@/api/factory.js";

export default {
  name: "Delete",

  components: {
    Confirm,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    header: {
      type: Object,
      required: true,
    },

    repositoryId: {
      type: Number,
      required: true,
    },

    itemId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    closeModal() {
      this.$emit("close", false);
    },

    async deleteFile() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await repository.fileDelete(
        this.repositoryId,
        this.itemId
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload.status === 200) {
        this.$alert.success(`file deleted successfully`);
      }
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
