<template>
  <BaseActionButton
    v-tooltip.top="'text color'"
    is-flat
    icon="format_color_text"
  >
    <q-menu
      anchor="bottom middle"
      self="top middle"
      :offset="[0, 8]"
      auto-close
      transition-show="scale"
      transition-hide="scale"
    >
      <div id="colors">
        <div
          v-for="color in colors"
          :key="color.id"
          class="color"
          :style="{ backgroundColor: color.value }"
          @click="handleInput(color)"
        >
          <BaseIcon
            v-if="activeColor === color.value"
            name="eva-checkmark"
            color="white"
          />
        </div>
      </div>
    </q-menu>
  </BaseActionButton>
</template>

<script>
export default {
  name: "TextColor",

  data() {
    return {
      colors: [
        {
          id: this.$nano.id(),
          label: "primary",
          value: "#643094",
        },
        {
          id: this.$nano.id(),
          label: "secondary",
          value: "#00bcd4",
        },
        {
          id: this.$nano.id(),
          label: "red",
          value: "#f44336",
        },
        {
          id: this.$nano.id(),
          label: "pink",
          value: "#e91e63",
        },
        {
          id: this.$nano.id(),
          label: "purple",
          value: "#9c27b0",
        },
        {
          id: this.$nano.id(),
          label: "deep-purple",
          value: "#673ab7",
        },
        {
          id: this.$nano.id(),
          label: "indigo",
          value: "#3f51b5",
        },
        {
          id: this.$nano.id(),
          label: "blue",
          value: "#2196f3",
        },
        {
          id: this.$nano.id(),
          label: "teal",
          value: "#009688",
        },
        {
          id: this.$nano.id(),
          label: "green",
          value: "#4caf50",
        },
        {
          id: this.$nano.id(),
          label: "light-green",
          value: "#8bc34a",
        },
        {
          id: this.$nano.id(),
          label: "lime",
          value: "#cddc39",
        },
        {
          id: this.$nano.id(),
          label: "amber",
          value: "#ffc107",
        },
        {
          id: this.$nano.id(),
          label: "orange",
          value: "#ff9800",
        },
        {
          id: this.$nano.id(),
          label: "deep-orange",
          value: "#ff5722",
        },
        {
          id: this.$nano.id(),
          label: "brown",
          value: "#795548",
        },
        {
          id: this.$nano.id(),
          label: "gray",
          value: "#64748b",
        },
        {
          id: this.$nano.id(),
          label: "none",
          value: "",
        },
      ],
      activeColor: "",
    };
  },

  methods: {
    handleInput(color) {
      this.activeColor = color.value;

      if (color.label === "none") {
        this.$emit("unset");
        return;
      }

      this.$emit("set", color.value);
    },
  },
};
</script>

<style lang="scss" scoped>
#colors {
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  gap: 8px;
  width: 104px;

  .color {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--divider-color);
  }
}
</style>
