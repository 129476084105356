<template>
  <BaseActionButton
    v-tooltip.top="'background color'"
    is-flat
    icon="eva-color-palette-outline"
  >
    <q-menu
      anchor="bottom middle"
      self="top middle"
      :offset="[0, 8]"
      auto-close
      transition-show="scale"
      transition-hide="scale"
    >
      <div id="colors">
        <div
          v-for="color in colors"
          :key="color.id"
          class="color"
          :style="{ backgroundColor: color.value }"
          @click="handleInput(color)"
        >
          <BaseIcon
            v-if="activeColor === color.value"
            name="eva-checkmark"
            color="white"
          />
        </div>
      </div>
    </q-menu>
  </BaseActionButton>
</template>

<script>
export default {
  name: "BackgroundColor",

  data() {
    return {
      colors: [
        {
          id: this.$nano.id(),
          label: "primary",
          value: "#dec2e7",
        },
        {
          id: this.$nano.id(),
          label: "secondary",
          value: "#b2ebf2",
        },
        {
          id: this.$nano.id(),
          label: "red",
          value: "#ffcdd2",
        },
        {
          id: this.$nano.id(),
          label: "pink",
          value: "#f8bbd0",
        },
        {
          id: this.$nano.id(),
          label: "purple",
          value: "#e1bee7",
        },
        {
          id: this.$nano.id(),
          label: "deep-purple",
          value: "#d1c4e9",
        },
        {
          id: this.$nano.id(),
          label: "indigo",
          value: "#c5cae9",
        },
        {
          id: this.$nano.id(),
          label: "blue",
          value: "#bbdefb",
        },
        {
          id: this.$nano.id(),
          label: "teal",
          value: "#b2dfdb",
        },
        {
          id: this.$nano.id(),
          label: "green",
          value: "#c8e6c9",
        },
        {
          id: this.$nano.id(),
          label: "lime",
          value: "#f0f4c3",
        },
        {
          id: this.$nano.id(),
          label: "amber",
          value: "#ffecb3",
        },
        {
          id: this.$nano.id(),
          label: "orange",
          value: "#ffe0b2",
        },
        {
          id: this.$nano.id(),
          label: "deep-orange",
          value: "#ffccbc",
        },
        {
          id: this.$nano.id(),
          label: "brown",
          value: "#d7ccc8",
        },
        {
          id: this.$nano.id(),
          label: "none",
          value: "",
        },
      ],
      activeColor: "",
    };
  },

  methods: {
    handleInput(color) {
      this.activeColor = color.value;

      if (color.label === "none") {
        this.$emit("unset");
        return;
      }

      this.$emit("set", color.value);
    },
  },
};
</script>

<style lang="scss" scoped>
#colors {
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  gap: 8px;
  width: 104px;

  .color {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--divider-color);
    cursor: pointer;
  }
}
</style>
