<template>
  <div id="pulse" :style="_style"></div>
</template>

<script>
import colorValidator from "@/helpers/color-validator.js";

export default {
  name: "Pulse",

  props: {
    color: {
      type: String,
      default: "secondary",
      validator: colorValidator,
    },
  },

  computed: {
    _style() {
      return `background-color: var(--${this.color}); color: var(--${this.color})`;
    },
  },
};
</script>

<style lang="scss" scoped>
#pulse {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  animation: pulse 1200ms infinite;
}

@keyframes pulse {
  0% {
    box-shadow: currentColor 0 0 0 0;
  }
  75% {
    box-shadow: rgba(0, 0, 0, 0) 0 0 0 10px;
  }
}
</style>
