<template>
  <Confirm
    :value="value"
    :icon="header.icon || ''"
    icon-color="primary"
    width="450px"
    @input="closeModal"
  >
    <!-- title -->

    <template #title>{{ header?.label }}</template>

    <!-- ... -->

    <template #default>
      <ValidationObserver ref="form">
        <!-- field -->

        <!-- <ValidationProvider
          v-slot="{ errors }"
          name="field"
          :rules="{ required: true }"
        >
          <TextField
            v-model="password"
            :label="
              header.id === 'Set_Password'
                ? 'enter a password for to protect this file'
                : 'enter a password to remove this file protection'
            "
            is-mandatory
            :error="errors[0]"
            class="q-my-lg"
          />
        </ValidationProvider> -->

        <!-- password -->

        <ValidationProvider
          v-slot="{ errors }"
          name="password"
          :rules="{ required: true, password: true }"
        >
          <PasswordField
            v-model="password"
            :label="
              header.id === 'Set_Password'
                ? 'enter a password for to protect this file'
                : 'enter a password to remove this file protection'
            "
            is-mandatory
            :tooltip="_passwordHint"
            :error="errors[0]"
            class="q-my-lg"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- confirm password -->

        <ValidationProvider
          v-slot="{ errors }"
          name="password"
          :rules="{ required: true, password: true }"
        >
          <PasswordField
            v-model="confirmPassword"
            label="confirm password"
            is-mandatory
            :tooltip="_passwordHint"
            :error="errors[0]"
            class="q-mb-lg"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- ... -->
      </ValidationObserver>
    </template>

    <!-- footer -->

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="closeModal" />

      <BaseButton label="save" @click="updatePassword" />
    </template>

    <!-- ... -->
  </Confirm>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Confirm from "@/components/common/popup/Confirm.vue";
// import TextField from "@/components/common/form/text-field/TextField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";
import { passwordHint } from "@/helpers/password-hint.js";
import { menu } from "@/api/factory.js";

export default {
  name: "Password",

  components: {
    Confirm,
    ValidationObserver,
    ValidationProvider,
    // TextField,
    PasswordField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    header: {
      type: Object,
      required: true,
    },

    repositoryId: {
      type: Number,
      required: true,
    },

    itemId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      password: "",
      confirmPassword: "",
    };
  },

  computed: {
    _passwordHint() {
      return passwordHint;
    },
  },

  methods: {
    closeModal() {
      this.$emit("close", false);
    },

    async updatePassword() {
      if (this.password === this.confirmPassword) {
        this.$store.commit("showLoadingBar");

        const { error, payload } = await menu.setRemoveFilePassword({
          repositoryId: this.repositoryId,
          itemId: this.itemId,
          password:
            this.header.id === "Set_Password" ? this.confirmPassword : "",
        });

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }

        // console.log(payload);
        if (payload.status === 202) {
          if (this.header.id === "Set_Password") {
            this.$alert.success(`password set successfully`);
          } else {
            this.$alert.success(`password removed successfully`);
          }
          this.password = "";
          this.confirmPassword = "";
        }
        this.$emit("close", false);
      } else {
        this.$alert.warning("password not matched");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
