<template>
  <div
    id="file-list-item"
    :class="{
      'is-selected': isSelected || file.id === fileId,
      'mini-mode': miniMode,
    }"
    @click="$emit('selectFile')"
    @mouseover="fileId = file.id"
    @mouseleave="fileId = 0"
  >
    <div class="avatar">
      <FileIcon :mime-type="type" />
    </div>

    <div class="q-ml-md">
      <div class="title" v-on="$listeners" @click="viewFile">
        {{ file.name }}
        <slot name="info" />
      </div>

      <div class="meta">
        <!-- repository -->

        <template v-if="repository">
          <div>{{ repository }}</div>
          <BaseIcon name="mdi-circle-small" />
        </template>

        <!-- ... -->

        <!-- created at -->

        <div>{{ createdAt }}</div>

        <!-- ... -->

        <!-- created by -->

        <template v-if="file.createdBy && !miniMode">
          <BaseIcon name="mdi-circle-small" />
          <div>{{ file.createdBy }}</div>
        </template>

        <!-- ... -->

        <!-- size -->

        <template v-if="file.size">
          <BaseIcon name="mdi-circle-small" />
          <div>{{ size }}</div>
        </template>

        <!-- ... -->

        <!-- ocrType -->

        <template v-if="file.ocrType">
          <BaseIcon name="mdi-circle-small" />
          <div>{{ file.ocrType }}</div>
        </template>

        <!-- ... -->

        <!-- status -->

        <slot name="status"></slot>

        <!-- ... -->
      </div>
    </div>

    <q-space />

    <slot></slot>
  </div>
</template>

<script>
import FileIcon from "@/components/common/FileIcon.vue";
import formatBytes from "@/helpers/format-bytes.js";

export default {
  name: "FileListItem",

  components: { FileIcon },

  props: {
    file: {
      type: Object,
      required: true,
    },

    isSelected: {
      type: Boolean,
      default: false,
    },

    miniMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fileId: 0,
    };
  },

  computed: {
    type() {
      return this.file.name.split(".").reverse()[0].slice(0, 4);
    },

    repository() {
      if (!this.file.repository) {
        return;
      }

      return this.file.repository.value;
    },

    createdAt() {
      return this.$day.format(this.file.createdAt);
    },

    size() {
      return formatBytes(this.file.size);
    },
  },

  methods: {
    viewFile() {
      this.$emit("view", this.file);
    },
  },
};
</script>

<style lang="scss" scoped>
#file-list-item {
  display: flex;
  align-items: center;
  padding: 16px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--divider-color);
  }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }
}

.theme-light {
  #file-list-item {
    &.is-selected {
      background-color: $gray-1;
    }
  }
}

.theme-dark {
  #file-list-item {
    &.is-selected {
      background-color: $dark-1;
    }
  }
}
</style>
