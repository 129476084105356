<template>
  <BaseActionButton
    v-tooltip.top="'filter'"
    is-flat
    no-border
    icon="eva-funnel-outline"
    :color="activeFilter ? 'secondary' : 'gray'"
    @click="model = true"
  >
    <Sheet v-model="model" width="50vw" has-footer>
      <template #title> Filter {{ item }}s </template>

      <template #default>
        <div id="filters" class="q-pa-md">
          <!-- <div class="description">
            Define filters to find exactly what you are looking for. Filters can
            be included or excluded. You can also add multiple filter groups.
          </div> -->

          <template v-if="searchContent">
            <div class="description">
              To search by content, type any text to search the entire folder
            </div>
            <TextField
              v-model="contentSearch.contentSearchValue"
              class="q-mb-md"
              :placeholder="'Search the document by content'"
            />

            <div
              class="q-mb-md"
              style="border-bottom: 1px solid var(--divider-color)"
            >
              <div v-if="showAdvanceOptions" class="q-my-md">
                <SingleChoiceField
                  v-model="contentSearch.searchType"
                  :options="searchOptions"
                  :options-per-line="2"
                  label="Advanced Search Options"
                  class="col q-mb-md"
                />

                <FormFieldLabel label="Search similarity" />
                <q-slider
                  v-model="contentSearch.fuzzy"
                  color="secondary"
                  markers
                  marker-labels
                  :min="0"
                  :max="10"
                  label
                />
              </div>

              <div class="row items-center q-mb-md">
                <div class="col">
                  <div
                    v-if="!showAdvanceOptions"
                    class="row items-center cursor-pointer"
                    @click="showAdvanceOptions = true"
                  >
                    <BaseIcon name="eva-plus" color="secondary" class="icon" />
                    <div class="options">Advanced Options</div>
                  </div>
                  <div
                    v-else
                    class="row items-center cursor-pointer"
                    @click="showAdvanceOptions = false"
                  >
                    <BaseIcon name="eva-minus" color="secondary" class="icon" />
                    <div class="options">Hide Advanced Options</div>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="actions">
                    <BaseButton
                      is-flat
                      label="clear"
                      class="q-mr-sm"
                      @click="clearContentSearch"
                    />

                    <BaseButton label="search" @click="apply" />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- <div class="description q-mt-sm">
            Type your search characters followed by * and press enter
          </div>
          <div
            class="description"
            style="font-size: 12px; color: #00bcd4; margin-top: 10px"
          >
            E.g., type <span style="color: #643094">INV*</span> to filter
            Invoice details.
          </div> -->

          <template v-if="filterGroups.length">
            <template v-for="(group, groupIdx) in filterGroups">
              <div :key="group.id" class="filter-group">
                <!-- header -->

                <div class="header">
                  <div class="label q-mr-md">Criteria</div>
                  <div class="label q-mr-md">Condition</div>
                  <div class="label q-mr-sm">Value</div>

                  <BaseActionButton
                    is-flat
                    color="secondary"
                    icon="eva-plus"
                    no-border
                    @click="addFilter(groupIdx)"
                  />
                </div>

                <!-- ... -->

                <BaseSeparator has-inset class="q-mb-md" />

                <!-- filters -->

                <ValidationObserver
                  v-for="(filter, filterIdx) in group.filters"
                  ref="form"
                  :key="filter.id"
                  class="filter row"
                >
                  <!-- criteria -->

                  <ValidationProvider
                    :key="filter.id"
                    v-slot="{ errors }"
                    name="criteria"
                    :rules="{ required: true }"
                    class="col q-mr-md"
                  >
                    <MultiSelectField
                      v-model="filter.criteriaArray"
                      is-mandatory
                      :is-searchable="true"
                      :options="_columns"
                      :error="errors[0]"
                      @input="
                        applyDataType(groupIdx, filterIdx, filter.criteriaArray)
                      "
                    />
                  </ValidationProvider>

                  <!-- ... -->

                  <!-- condition -->

                  <ValidationProvider
                    v-slot="{ errors }"
                    name="condition"
                    :rules="{ required: true }"
                    class="col-3 q-mr-md"
                  >
                    <SelectField
                      v-model="filter.condition"
                      is-mandatory
                      :is-searchable="true"
                      :options="conditions(filter.criteriaArray)"
                      :error="errors[0]"
                    />
                  </ValidationProvider>

                  <!-- ... -->

                  <!-- value -->

                  <ValidationProvider
                    v-if="
                      filter.dataType === 'SHORT_TEXT' ||
                      filter.dataType === 'BARCODE'
                    "
                    v-slot="{ errors }"
                    name="value"
                    :rules="{
                      required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                        filter.condition
                      ),
                    }"
                    class="col-3 q-mr-sm"
                  >
                    <TextField
                      v-model="filter.value"
                      is-mandatory
                      :is-disabled="
                        ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                      "
                      :error="errors[0]"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-else-if="filter.dataType === 'NUMBER'"
                    v-slot="{ errors }"
                    name="value"
                    :rules="{
                      required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                        filter.condition
                      ),
                    }"
                    class="col q-mr-sm"
                  >
                    <NumberField
                      v-model="filter.value"
                      is-mandatory
                      :is-disabled="
                        ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                      "
                      :error="errors[0]"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-else-if="filter.dataType === 'DATE'"
                    v-slot="{ errors }"
                    name="value"
                    :rules="{
                      required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                        filter.condition
                      ),
                    }"
                    class="col q-mr-sm"
                  >
                    <DateField
                      v-model="filter.value"
                      is-mandatory
                      :is-disabled="
                        ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                      "
                      :error="errors[0]"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-else-if="filter.dataType === 'TIME'"
                    v-slot="{ errors }"
                    name="value"
                    :rules="{
                      required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                        filter.condition
                      ),
                    }"
                    class="col q-mr-sm"
                  >
                    <TimeField
                      v-model="filter.value"
                      is-mandatory
                      :is-disabled="
                        ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                      "
                      :error="errors[0]"
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-else-if="filter.dataType === 'SINGLE_SELECT'"
                    v-slot="{ errors }"
                    name="value"
                    :rules="{
                      required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                        filter.condition
                      ),
                    }"
                    class="col q-mr-sm"
                  >
                    <template v-if="checkOptions(filter.criteriaArray)">
                      <AsyncMultiSelectField
                        v-model="filter.arrayValue"
                        is-mandatory
                        :is-disabled="
                          ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                            filter.condition
                          )
                        "
                        :error="errors[0]"
                        :api-path="apiPath"
                        :column-name="filter.criteriaArray"
                      />
                      <AsyncSelectField
                        v-if="false"
                        v-model="filter.value"
                        is-mandatory
                        :is-disabled="
                          ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                            filter.condition
                          )
                        "
                        :error="errors[0]"
                        :api-path="apiPath"
                        :column-name="filter.criteria"
                      />
                    </template>
                    <template v-else>
                      <SelectField
                        v-if="false"
                        v-model="filter.value"
                        is-mandatory
                        :is-disabled="
                          ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                            filter.condition
                          )
                        "
                        :is-searchable="true"
                        :options="getOptions(filter.criteriaArray)"
                        :error="errors[0]"
                      />
                      <MultiSelectField
                        v-model="filter.arrayValue"
                        is-mandatory
                        :is-disabled="
                          ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                            filter.condition
                          )
                        "
                        :is-searchable="true"
                        :options="getOptions(filter.criteriaArray)"
                        :error="errors[0]"
                      />
                    </template>
                  </ValidationProvider>

                  <ValidationProvider
                    v-else
                    v-slot="{ errors }"
                    name="value"
                    :rules="{
                      required: !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(
                        filter.condition
                      ),
                    }"
                    class="col q-mr-sm"
                  >
                    <TextField
                      v-model="filter.value"
                      is-mandatory
                      :is-disabled="
                        ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(filter.condition)
                      "
                      :error="errors[0]"
                    />
                  </ValidationProvider>

                  <!-- ... -->

                  <!-- remove -->

                  <div class="remove-filter">
                    <BaseActionButton
                      is-flat
                      color="red"
                      no-border
                      icon="eva-close-outline"
                      @click="removeFilter(groupIdx, filterIdx)"
                    />
                  </div>

                  <!-- ... -->
                </ValidationObserver>

                <!-- ... -->

                <BaseSeparator has-inset />

                <div class="row">
                  <Action
                    icon="eva-plus-outline"
                    label="Add Filter Group"
                    class="col"
                    @click="addFilterGroup(groupIdx + 1)"
                  />

                  <BaseSeparator is-vertical has-inset />

                  <Action
                    icon="eva-trash-2-outline"
                    label="Delete Group"
                    class="col"
                    @click="removeFilterGroup(groupIdx)"
                  />
                </div>
              </div>

              <div
                v-if="groupIdx !== filterGroups.length - 1"
                :key="groupIdx"
                class="row justify-center"
              >
                <Toggle
                  v-model="group.groupCondition"
                  :options="groupConditions"
                  class="q-my-md"
                  @input="groupCondition"
                />
              </div>
            </template>
          </template>

          <template v-else>
            <div class="add-filter" @click="addFilterGroup">
              <BaseIcon name="eva-plus" inherit-color />

              <div class="q-ml-sm">Add Filter</div>
            </div>
          </template>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="clear"
          class="q-mr-sm"
          @click="clearFilter"
        />

        <BaseButton label="apply" @click="apply" />
      </template>
    </Sheet>
  </BaseActionButton>
</template>

<script>
import { lowerCase, startCase } from "lodash-es";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import Action from "@/components/common/Action.vue";
import Toggle from "@/components/common/Toggle.vue";
import Conditions from "@/helpers/conditions.js";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import AsyncMultiSelectField from "@/components/common/form/async-select-field/AsyncMultiSelectField.vue";
import AsyncSelectField from "@/components/common/form/async-select-field/AsyncSelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import { user } from "@/api/factory.js";
import { axiosCrypto } from "@/api/axios.js";

import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";

export default {
  name: "FilterItems",

  components: {
    ValidationObserver,
    ValidationProvider,
    Sheet,
    TextField,
    SelectField,
    Action,
    Toggle,
    NumberField,
    DateField,
    TimeField,
    AsyncMultiSelectField,
    MultiSelectField,
    AsyncSelectField,
    FormFieldLabel,
    SingleChoiceField,
  },

  props: {
    item: {
      type: String,
      default: "item",
    },

    columns: {
      type: Array,
      required: true,
    },

    filterBy: {
      type: Array,
      required: true,
    },

    isBordered: {
      type: Boolean,
      default: false,
    },

    selectedFilter: {
      type: Array,
      default: () => [],
    },

    searchContent: {
      type: Boolean,
      default: false,
    },

    contentSearch: {
      type: Object,
      default: () => {
        return { contentSearchValue: "", fuzzy: 0, searchType: 1 };
      },
    },

    module: {
      type: String,
      default: "",
    },

    moduleId: {
      type: [String, Number],
      default: 0,
    },
  },

  data() {
    return {
      model: false,
      groupConditions: ["AND", "OR"],
      filterGroups: [],
      activeFilter: false,
      userList: [],
      options: [],
      showAdvanceOptions: false,
      searchOptions: [
        {
          id: this.$nano.id(),
          label: "Any of the Words",
          value: 1,
        },
        {
          id: this.$nano.id(),
          label: "All of the Words",
          value: 2,
        },
      ],
    };
  },

  computed: {
    _columns() {
      var data = this.columns.reduce((ids, column) => {
        if ("isFilter" in column) {
          if (column.isFilter) {
            ids.push({
              id: column.id,
              label: column.label,
              value: column.name,
              dataType: column.dataType,
            });
          }
        } else {
          ids.push({
            id: column.id,
            label: column.label,
            value: column.name,
            dataType: column.dataType,
          });
        }
        return ids;
      }, []);
      // console.log(data);
      return data;
      // return this.columns.map((column) => ({
      //   id: column.id,
      //   label: column.label,
      //   value: column.name,
      // }));
    },

    apiPath() {
      if (this.module === "repository") {
        if (this.$route.query.repositoryId) {
          return `/repository/${this.moduleId}/uniqueColumnValues`;
        }
      } else if (this.module === "form") {
        return `/form/${this.moduleId}/uniqueColumnValues`;
      }
      return "";
    },
  },

  watch: {
    model: {
      deep: true,
      handler() {
        if (this.model) {
          // this.addFilterGroup(0);
        }
      },
    },

    $route: {
      deep: true,
      handler() {
        if (!this.$route.query.repositoryId) {
          this.filterGroups = [];
          this.activeFilter = false;
        }
      },
    },

    filterBy: {
      deep: true,
      immediate: true,
      handler() {
        if (
          this.filterBy.length === 0 &&
          this.contentSearch.contentSearchValue === ""
        ) {
          this.activeFilter = false;
        }
      },
    },

    contentSearch: {
      deep: true,
      immediate: true,
      handler() {
        if (
          this.filterBy.length === 0 &&
          this.contentSearch.contentSearchValue === ""
        ) {
          this.activeFilter = false;
        }
      },
    },
  },

  created() {
    this.getUsers();
  },

  methods: {
    conditions(columnName) {
      if (!columnName) {
        return [];
      }

      // console.log(columnName);
      // console.log(this.columns);

      if (columnName.length === 1) {
        const dataType = this.columns.find(
          (column) => column.name === columnName[0]
        );
        if (dataType) {
          return Conditions(dataType.dataType).map((condition) => ({
            id: this.$nano.id(),
            label: `${startCase(lowerCase(condition.split(" ")[0]))} ${
              condition.split(" ")[1] ? condition.split(" ")[1] : ""
            }`,
            value: condition.split(" ")[0],
          }));
        }
      } else {
        return Conditions("SHORT_TEXT").map((condition) => ({
          id: this.$nano.id(),
          label: `${startCase(lowerCase(condition.split(" ")[0]))} ${
            condition.split(" ")[1] ? condition.split(" ")[1] : ""
          }`,
          value: condition.split(" ")[0],
        }));
      }
    },

    addFilterGroup(groupIdx = 0) {
      this.filterGroups.splice(groupIdx, 0, {
        id: this.$nano.id(),
        filters: [
          {
            id: this.$nano.id(),
            criteria: "",
            criteriaArray: [],
            condition: "",
            value: "",
            arrayValue: [],
          },
        ],
        groupCondition: this.filterGroups.length ? "" : "",
      });

      if (this.filterGroups.length) {
        if (this.selectedFilter.length) {
          if (this.selectedFilter[1] && this.selectedFilter[1].level === 1) {
            // this.filterGroups[0].filters[0].criteria =
            //   this.selectedFilter[1].fieldName;
            // this.filterGroups[0].filters[0].value =
            //   this.selectedFilter[1].label;
            // this.filterGroups[0].filters[0].condition = "IS_EQUALS_TO";
            // this.applyDataType(0, 0, this.selectedFilter[1].fieldName);
            this.filterGroups[0].filters = [];
            this.selectedFilter.forEach((item, index) => {
              if (index != 0) {
                this.filterGroups[0].filters.push({
                  id: this.$nano.id(),
                  criteria: item.fieldName,
                  condition: "IS_EQUALS_TO",
                  value: item.label,
                  arrayValue: item.arrayValue || [],
                });
              }
            });
          }
        }
      }
    },

    removeFilterGroup(groupIdx) {
      this.filterGroups.splice(groupIdx, 1);
    },

    addFilter(groupIdx) {
      this.filterGroups[groupIdx].filters.push({
        id: this.$nano.id(),
        criteria: "",
        criteriaArray: [],
        condition: "CONTAINS",
        value: "",
        arrayValue: [],
      });
    },

    removeFilter(groupIdx, filterIdx) {
      this.filterGroups[groupIdx].filters.splice(filterIdx, 1);
    },

    async apply() {
      let invalidFields = 0;
      let input = {};

      if (!this.$refs.form) {
        if (this.searchContent) {
          this.$emit("update", this.filterGroups, input);
          this.model = false;
          this.activeFilter = true;
        }
        return;
      }

      for (const form of this.$refs.form) {
        const isValid = await form.validate();
        if (!isValid) {
          invalidFields++;
        }
      }

      if (invalidFields) {
        return;
      }

      let multiCriteria = false;

      if (this.filterGroups.length) {
        this.filterGroups[0].groupCondition = "";
        if (!this.filterGroups[0].filters.length) {
          this.filterGroups = [];
        }
        this.filterGroups.forEach((group) => {
          if (group.filters.length) {
            group.filters.forEach((row) => {
              if (row.criteriaArray.length > 1) {
                multiCriteria = true;
              } else {
                row.criteria = row.criteriaArray[0];
              }
              if (row.dataType === "SINGLE_SELECT") {
                row.value = JSON.stringify(row.arrayValue);
              }
            });
          }
        });
      }

      if (multiCriteria) {
        let multiFilters = [];
        this.filterGroups.forEach((group) => {
          if (group.filters.length) {
            group.filters.forEach((row) => {
              row.criteriaArray.forEach((value, index) => {
                multiFilters.push({
                  filters: [
                    {
                      criteria: value,
                      condition: row.condition,
                      value: row.value,
                      dataType: row.dataType,
                    },
                  ],
                  groupCondition: index ? "OR" : "",
                });
              });
            });
          }
        });
        this.$emit("update", multiFilters, input);
      } else {
        this.$emit("update", this.filterGroups, input);
      }

      this.model = false;
      this.activeFilter = true;
    },

    applyDataType(groupIdx, filterIdx, name) {
      // console.log(groupIdx, filterIdx, name);
      this.filterGroups[groupIdx].filters[filterIdx].condition = "";
      if (name.length === 1) {
        this.filterGroups[groupIdx].filters[filterIdx].dataType = "";
        // console.log(this.filterGroups);
        let type = this._columns.find(
          (item) => item.label === name[0] || item.value === name[0]
        );
        if (type) {
          this.filterGroups[groupIdx].filters[filterIdx].dataType =
            type.dataType;
          if (type.dataType === "SHORT_TEXT") {
            this.filterGroups[groupIdx].filters[filterIdx].condition =
              "CONTAINS";
          } else if (type.dataType === "SINGLE_SELECT") {
            this.filterGroups[groupIdx].filters[filterIdx].condition =
              "IS_EQUALS_TO";
          }
        }

        let obj = this.filterGroups[groupIdx].filters[filterIdx];

        if (obj.dataType === "SINGLE_SELECT") {
          this.filterGroups[groupIdx].filters[filterIdx].value = [];
        } else {
          this.filterGroups[groupIdx].filters[filterIdx].value = "";
        }
      } else {
        this.filterGroups[groupIdx].filters[filterIdx].condition = "CONTAINS";
        this.filterGroups[groupIdx].filters[filterIdx].dataType = "SHORT_TEXT";
        this.filterGroups[groupIdx].filters[filterIdx].value = "";
      }
    },

    clearFilter() {
      this.filterGroups = [];
      this.activeFilter = false;
      // this.model = false;
      this.clearContentSearch();
      this.$emit("update", this.filterGroups, "");
    },

    clearContentSearch() {
      this.showAdvanceOptions = false;
      this.contentSearch.contentSearchValue = "";
      this.contentSearch.searchType = 1;
      this.contentSearch.fuzzy = 0;
      this.$emit("updateContentSearch", this.contentSearch);
    },

    groupCondition(condition) {
      // console.log(condition);
      this.filterGroups[this.filterGroups.length - 1].groupCondition =
        condition;
    },

    checkOptions(name) {
      if (name.length === 1) {
        let column = this.columns.find((column) => column.name === name[0]);
        if (column) {
          if (column.apiLoad) {
            return true;
          }
        }
      }
      return false;
    },

    getOptions(name) {
      if (name.length === 1) {
        if (
          name[0] === "createdBy" ||
          name[0] === "modifiedBy" ||
          name[0] === "owner" ||
          name[0] === "coordinator"
        ) {
          return this.userList;
        }
        return this.columns.find((column) => column.name === name[0])?.options;
      } else {
        return [];
      }
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      if (payload) {
        payload.map((user) => {
          this.userList.push({
            id: this.$nano.id(),
            label: user.value || user.loginName,
            value: String(user.id),
          });
        });
      }
    },

    async getUniqueOptions(name) {
      try {
        const response = await axiosCrypto.post(
          this.apiPath,
          JSON.stringify({
            column: name,
            keyword: "",
            rowFrom: 0,
            rowCount: 10, // + 10,
          })
        );
        const { status, data } = response;

        if (status !== 200) {
          throw response;
        }

        const dataOptions = JSON.parse(data);
        this.options = [];
        dataOptions.forEach((value) => {
          let user = this.userList.find((row) => row.value === value);
          if (user) {
            this.options.push({
              id: this.$nano.id(),
              label: user.label,
              value: user.value,
            });
          }
        });
        return this.options;
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#filters {
  padding: 16px;

  .description {
    margin-bottom: 12px;
  }

  .add-filter {
    display: flex;
    align-items: center;
    color: var(--secondary);
    cursor: pointer;
  }

  .filter-group {
    border: 1px solid var(--divider-color);
    border-radius: 4px;

    .header {
      display: flex;
      align-items: center;
      padding: 8px 8px 8px 16px;

      .label {
        @extend .text-sm;
        color: var(--icon-color);
        flex: 1;
      }
    }

    .filter {
      display: flex;
      padding: 0px 8px 16px 16px;

      :deep #field div {
        // width: 137px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 0px;
      }
    }

    .remove-filter {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .add-filter-group {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .label {
      color: var(--secondary);
      margin-left: 8px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .options {
    color: var(--secondary);
    margin-left: 8px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 16px;
    height: 56px;
    //border-bottom: 1px solid var(--divider-color);
  }
}
</style>
