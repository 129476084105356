<template>
  <Sheet
    :value="value"
    :no-padding="true"
    has-footer
    width="650px"
    @input="$emit('input', false)"
  >
    <!-- title -->

    <template #title>Document Sharing By Email</template>

    <!-- ... -->

    <template #default>
      <div id="document-sharing-details">
        <Tabs v-model="tab" :tabs="tabs" class="tabs" />
        <BaseScrollbar height="calc(100vh - 170px)">
          <div class="q-pa-md">
            <ValidationObserver ref="form">
              <div class="field-wrapper">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="to"
                  :rules="{ required: true, email: true }"
                >
                  <MultiSelectField
                    v-model="email"
                    label="to"
                    is-mandatory
                    new-option
                    :error="errors[0]"
                    class="q-mb-sm"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="subject"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="subject"
                    label="subject"
                    is-mandatory
                    :error="errors[0]"
                    class="q-mb-sm"
                  />
                </ValidationProvider>

                <template v-if="tab === 'attach link'">
                  <div class="row">
                    <div class="col-6 q-pr-sm">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Expiry from Date"
                        :rules="{ required: true }"
                      >
                        <DateField
                          v-model="fromDate"
                          label="Expiry from Date"
                          is-mandatory
                          :error="errors[0]"
                          class="q-mb-sm"
                          :minimum-validation="true"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="col-6 q-pl-sm">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Expiry to Date"
                        :rules="{ required: true }"
                      >
                        <DateField
                          v-model="toDate"
                          label="Expiry to Date"
                          is-mandatory
                          :error="errors[0]"
                          class="q-mb-sm"
                          :minimum-validation="true"
                        />
                      </ValidationProvider>
                    </div>
                  </div>
                </template>

                <template v-for="file in fileList">
                  <q-chip
                    :key="file.id"
                    outline
                    color="secondary"
                    class="chips secondary-hover cursor-pointer"
                  >
                    <q-avatar
                      icon="mdi-file"
                      color="secondary"
                      text-color="white"
                      @click="remove(file.id)"
                    />
                    {{ file.name }}

                    <q-avatar
                      icon="mdi-close"
                      class="close-icon"
                      text-color="red"
                      @click="remove(file.id)"
                    />
                  </q-chip>
                </template>

                <TextBuilderField v-model="body" />
              </div>
            </ValidationObserver>
          </div>
        </BaseScrollbar>
      </div>
    </template>

    <!-- footer -->

    <template #footer>
      <BaseButton
        is-flat
        label="cancel"
        class="q-mr-sm"
        @click="$emit('input', false)"
      />

      <BaseButton label="send" @click="documentShare" />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import TextField from "@/components/common/form/text-field/TextField";
import DateField from "@/components/common/form/date-field/DateField";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import { menu } from "@/api/factory.js";

export default {
  name: "EmailSharing",

  components: {
    Sheet,
    ValidationObserver,
    ValidationProvider,
    TextField,
    Tabs,
    DateField,
    MultiSelectField,
    TextBuilderField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    repositoryId: {
      type: Number,
      default: 0,
    },

    fileList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      tab: "attach file",
      tabs: [
        {
          id: this.$nano.id(),
          label: "attach file",
          value: "attach file",
        },
        {
          id: this.$nano.id(),
          label: "attach link",
          value: "attach link",
        },
      ],
      email: [],
      subject: "",
      body: "",
      fromDate: "",
      toDate: "",
      minimumFromDate: "",
    };
  },

  created() {
    // Example: Set minimumFromDate to today's date
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0]; // Format YYYY-MM-DD
    this.minimumFromDate = formattedDate;
  },

  methods: {
    clearFields() {
      this.tab = "attach file";
      this.email = [];
      this.subject = "";
      this.body = "";
      this.fromDate = "";
      this.toDate = "";
    },

    remove(fileId) {
      if (this.fileList.length > 1) {
        let list = this.fileList.filter((file) => file.id != fileId);
        this.$emit("update:fileList", list);
      } else {
        this.$alert.warning("Minimum one document is required");
      }
    },

    async documentShare() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }
      let fileIds = [];
      this.fileList.forEach((item) => {
        fileIds.push(item.id);
      });
      if (this.tab === "attach file") {
        let input = {
          repositoryId: this.repositoryId,
          itemIds: fileIds,
          toAdd: this.email.toString(),
          subject: this.subject,
          body: this.body,
        };
        const { error } = await menu.documentShare(input);

        if (error) {
          this.$alert.error(error);
          return;
        }
      } else if (this.tab === "attach link") {
        if (this.toDate < this.fromDate) {
          this.$alert.warning(
            "ToDate Is Must Be Greater Than Or Equal To FromDate"
          );
          return;
        }
        let input = {
          toAddress: this.email.toString(),
          subject: this.subject,
          body: this.body,
          expiryFromDate: this.fromDate,
          expiryTodate: this.toDate,
          repositoryId: this.repositoryId,
          itemIdArray: fileIds,
        };
        const { error } = await menu.documentShareLink(input);
        if (error) {
          this.$alert.error(error);
          return;
        }
      }

      this.$alert.success("Initiated Document Sharing by Email");
      this.clearFields();
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#document-sharing-details {
  .tabs {
    padding: 0px 16px;
    border-bottom: 1px solid var(--divider-color);
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .close-icon {
    margin-left: 5px !important;
  }
}
</style>
