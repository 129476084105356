<template>
  <BaseActionButton v-tooltip.top="'heading'" is-flat icon="mdi-format-size">
    <q-menu
      anchor="bottom middle"
      self="top middle"
      :offset="[0, 8]"
      transition-show="scale"
      transition-hide="scale"
    >
      <BaseActionButton
        v-for="heading in headings"
        :key="heading.id"
        v-tooltip.right="heading.label"
        is-flat
        :icon="heading.icon"
        class="q-ma-sm"
        @click="handleClick(heading.level)"
      />
    </q-menu>
  </BaseActionButton>
</template>

<script>
export default {
  name: "Heading",

  data() {
    return {
      headings: [
        {
          id: this.$nano.id(),
          label: "heading 1",
          icon: "mdi-format-header-1",
          level: 1,
        },
        {
          id: this.$nano.id(),
          label: "heading 2",
          icon: "mdi-format-header-2",
          level: 2,
        },
        {
          id: this.$nano.id(),
          label: "heading 3",
          icon: "mdi-format-header-3",
          level: 3,
        },
        {
          id: this.$nano.id(),
          label: "heading 4",
          icon: "mdi-format-header-4",
          level: 4,
        },
        {
          id: this.$nano.id(),
          label: "heading 5",
          icon: "mdi-format-header-5",
          level: 5,
        },
        {
          id: this.$nano.id(),
          label: "heading 6",
          icon: "mdi-format-header-6",
          level: 6,
        },
        {
          id: this.$nano.id(),
          label: "normal",
          icon: "mdi-format-paragraph",
          level: 0,
        },
      ],
    };
  },

  methods: {
    handleClick(level) {
      if (!level) {
        this.$emit("unset");
        return;
      }

      this.$emit("set", level);
    },
  },
};
</script>

<style lang="scss" scoped></style>
