<template>
  <Sheet :value="value" has-footer width="540px" @input="closeModal">
    <!-- title -->

    <template #title>Protect using password</template>

    <!-- ... -->

    <template #default>
      <div id="vault-details">
        <Tabs v-model="tab" :tabs="tabs" class="tabs" />
        <div class="q-pa-md">
          <ValidationObserver v-if="tab === 'form'" ref="form">
            <div class="field-wrapper">
              <FormFieldLabel
                label="Requires user to enter a password for"
                is-mandatory
              />

              <div class="options-wrapper">
                <CheckboxField
                  :value="conditions.includes('Viewing')"
                  label="Viewing"
                  class="q-mb-md"
                  @input="updateValue('Viewing')"
                />

                <CheckboxField
                  :value="conditions.includes('Editing')"
                  label="Editing"
                  class="q-mb-md"
                  @input="updateValue('Editing')"
                />
              </div>

              <FormFieldError v-if="error" :error="error" />

              <ValidationProvider
                v-slot="{ errors }"
                name="password"
                :rules="{
                  password: true,
                }"
              >
                <PasswordField
                  is-mandatory
                  label="password"
                  :value="password"
                  :error="errors[0]"
                  class="q-mb-sm"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="confirm password"
                :rules="{
                  password: true,
                }"
              >
                <PasswordField
                  is-mandatory
                  label="confirm password"
                  :value="password"
                  :error="errors[0]"
                  class="q-mb-sm"
                />
              </ValidationProvider>

              <div
                class="advanced q-py-sm q-mb-sm"
                @click="showAdvanceOptions = !showAdvanceOptions"
              >
                <div class="row items-center">
                  <div class="label">Advanced Options</div>
                  <BaseIcon
                    name="eva-chevron-down"
                    inherit-color
                    class="icon"
                  />
                </div>
              </div>

              <template v-if="showAdvanceOptions">
                <FormFieldLabel
                  label="Requires user to enter a password for"
                  is-mandatory
                />

                <div class="options-wrapper">
                  <CheckboxField
                    :value="conditions.includes('Links')"
                    label="Links"
                    class="q-mb-md"
                    @input="updateValue('Links')"
                  />

                  <CheckboxField
                    :value="conditions.includes('Comments')"
                    label="Comments"
                    class="q-mb-md"
                    @input="updateValue('Comments')"
                  />

                  <CheckboxField
                    :value="conditions.includes('Share')"
                    label="Share"
                    class="q-mb-md"
                    @input="updateValue('Share')"
                  />

                  <CheckboxField
                    :value="conditions.includes('Task')"
                    label="Task"
                    class="q-mb-md"
                    @input="updateValue('Task')"
                  />
                </div>
              </template>
            </div>
          </ValidationObserver>

          <div v-if="tab === 'history'" id="history">
            <template>
              <div class="entry">
                <div class="header">1 items protected</div>

                <div class="content">
                  <div class="changes-meta">
                    <div>admin@ezofis.com</div>
                    <BaseIcon name="mdi-circle-small" class="q-mx-xs" />
                    <div>2022-May-06 09:35 PM</div>
                  </div>

                  <div class="change">
                    <BaseIcon name="mdi-circle-small" class="q-mr-sm" />

                    <div>
                      <span class="font-semibold"> "Editing" </span>
                      menu protected
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!-- <div v-else class="no-history">No data found</div> -->
          </div>
        </div>
      </div>
    </template>

    <!-- footer -->

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="closeModal" />

      <BaseButton label="save" />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";

export default {
  name: "AddVault",

  components: {
    Sheet,
    ValidationObserver,
    ValidationProvider,
    PasswordField,
    FormFieldLabel,
    CheckboxField,
    FormFieldError,
    Tabs,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tab: "form",
      tabs: [
        {
          id: this.$nano.id(),
          label: "form",
          value: "form",
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "history",
        },
      ],
      password: "",
      conditions: [],
      error: "",
      showAdvanceOptions: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#vault-details {
  .tabs {
    padding: 0px 16px;
    border-bottom: 1px solid var(--divider-color);
  }

  .add-rule {
    padding: 8px 16px;
    background-color: var(--hover-bg-color);
    border-radius: 4px;
    font-weight: 500;
    text-transform: capitalize;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &:hover {
      cursor: pointer;
      color: white;
      background-color: var(--secondary);
    }
  }

  .advanced {
    // padding: 4px 4px 4px 0px;
    // margin: 0px 8px;
    // border: 1px solid var(--divider-color);
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: 500;
    cursor: pointer;

    .label {
      flex: 1;
      color: var(--secondary);
      margin-right: 8px;
    }

    .icon {
      color: var(--icon-color-inverted);
    }
  }
}

#history {
  padding: 16px;

  .no-history {
    font-weight: 500;
    padding: 16px 0px;
    text-align: center;
  }

  .entry {
    padding-left: 24px;
    padding-bottom: 24px;
    position: relative;

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      left: 0px;
      top: 6px;
      background-color: var(--secondary);
      border-radius: 100%;
    }

    &:not(:last-child):after {
      content: "";
      width: 2px;
      height: calc(100% - 6px);
      position: absolute;
      left: 3px;
      top: 14px;
      background-color: var(--hover-bg-color);
    }

    .header {
      color: var(--title-1-color);
      font-weight: 600;
      margin-bottom: 4px;
    }

    .content {
      .changes-meta {
        display: flex;
        align-items: center;
        @extend .text-sm;
        color: var(--icon-color);
        margin-bottom: 16px;
      }

      .change {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        margin-left: -6px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
