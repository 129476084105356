<template>
  <div id="repository-list">
    <div class="title">Folder</div>

    <div class="wrapper">
      <ValidationProvider
        v-slot="{ errors }"
        name="repository"
        :rules="{ required: true }"
      >
        <SelectField
          :value="value"
          is-mandatory
          :is-clearable="false"
          :options="repositories"
          is-readonly
          :error="errors[0]"
          @input="handleInput"
        />
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import { repository } from "@/api/factory.js";
import { ValidationProvider } from "vee-validate";
import SelectField from "@/components/common/form/select-field/SelectField.vue";

export default {
  name: "RepositoryList",

  components: { ValidationProvider, SelectField },

  props: {
    value: {
      type: Number,
      required: true,
    },

    workspaceId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      repositories: [],
    };
  },

  watch: {
    workspaceId: {
      immediate: true,
      handler: "getRepositories",
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    /* api Functions */

    async getRepositories() {
      if (this.workspaceId) {
        this.repositories = [];
        this.$store.commit("showLoadingBar");

        const { error, payload } = await repository.getRepositoryList(
          "workspaceId",
          this.workspaceId
        );

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (!payload.length) {
          return;
        }
        this.repositories = payload.map((repository) => ({
          id: this.$nano.id(),
          label: repository.value,
          value: repository.id,
        }));
      }
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#repository-list {
  .title {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
    padding: 16px;
  }

  .wrapper {
    padding: 0px 16px;
  }
}
</style>
