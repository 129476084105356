<template>
  <div>
    <div id="repository-field">
      <!-- text -->

      <div v-if="showFreeze" id="checkbox-field">
        <BaseIcon
          v-model="freezed"
          v-tooltip.top="'Freeze'"
          :name="
            freezed ? 'eva-checkmark-circle-2' : 'eva-radio-button-off-outline'
          "
          color="secondary"
          class="q-mr-sm"
          @click="handleFreeze"
        />
      </div>

      <!-- short_text -->

      <ValidationProvider
        v-if="field.dataType === 'SHORT_TEXT' || field.dataType === 'BARCODE'"
        v-slot="{ errors }"
        :name="field.name"
        :rules="{ required: field.isMandatory }"
        class="full-width"
      >
        <TextField
          :value="value"
          :is-mandatory="field.isMandatory"
          :label="label || field.name"
          :error="errors[0]"
          :is-readonly="readOnly"
          @input="handleInput"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- long text -->
      <ValidationProvider
        v-if="field.dataType === 'LONG_TEXT'"
        v-slot="{ errors }"
        :name="field.name"
        :rules="{ required: field.isMandatory }"
        class="full-width"
      >
        <TextAreaField
          :value="value"
          :is-mandatory="field.isMandatory"
          :label="label || field.name"
          :error="errors[0]"
          :is-readonly="readOnly"
          @input="handleInput"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- number -->

      <ValidationProvider
        v-if="field.dataType === 'NUMBER'"
        v-slot="{ errors }"
        :name="field.name"
        :rules="{
          required: field.isMandatory,
          integer: !field.options.allowDecimals,
        }"
        class="full-width"
      >
        <NumberField
          :value="value"
          :is-mandatory="field.isMandatory"
          :label="label || field.name"
          :error="errors[0]"
          :is-readonly="readOnly"
          @input="handleInput"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- date -->

      <ValidationProvider
        v-if="field.dataType === 'DATE'"
        v-slot="{ errors }"
        :name="field.name"
        :rules="{ required: field.isMandatory }"
        class="full-width"
      >
        <DateField
          :value="value"
          :is-mandatory="field.isMandatory"
          :label="label || field.name"
          :error="errors[0]"
          :is-readonly="readOnly"
          @input="handleInput"
        />
        <label
          v-if="
            expiry && value && value < new Date().toISOString().split('T')[0]
          "
          class="text-red q-mt-sm"
          >The chosen date is expired</label
        >
      </ValidationProvider>

      <!-- ... -->

      <!-- time -->

      <ValidationProvider
        v-if="field.dataType === 'TIME'"
        v-slot="{ errors }"
        :name="field.name"
        :rules="{ required: field.isMandatory }"
        class="full-width"
      >
        <TimeField
          :value="value"
          :is-mandatory="field.isMandatory"
          :label="label || field.name"
          :error="errors[0]"
          :is-readonly="readOnly"
          @input="handleInput"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- date time -->

      <ValidationProvider
        v-if="field.dataType === 'DATE_TIME'"
        v-slot="{ errors }"
        :name="field.name"
        :rules="{ required: field.isMandatory }"
        class="full-width"
      >
        <DateTimeField
          :value="value"
          :is-mandatory="field.isMandatory"
          :label="label || field.name"
          :error="errors[0]"
          :is-readonly="readOnly"
          @input="handleInput"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- select (EXISTING) && select (MASTER) -->

      <ValidationProvider
        v-if="
          field.dataType === 'SINGLE_SELECT' &&
          ['EXISTING'].includes(field.options.optionsType)
        "
        v-slot="{ errors }"
        :name="field.name"
        :rules="{ required: field.isMandatory }"
        class="full-width"
      >
        <AsyncSelectField
          :value="value"
          :is-mandatory="field.isMandatory"
          :label="label || field.name"
          :api-path="apiPath"
          :error="errors[0]"
          :new-option="field.options.allowToAddNewOptions"
          :is-readonly="readOnly"
          @input="handleInput"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- select (PREDEFINED) -->

      <ValidationProvider
        v-if="
          field.dataType === 'SINGLE_SELECT' &&
          field.options.optionsType === 'PREDEFINED'
        "
        v-slot="{ errors }"
        :name="field.name"
        :rules="{ required: field.isMandatory }"
        class="full-width"
      >
        <SelectField
          :value="value"
          :is-mandatory="field.isMandatory"
          :label="label || field.name"
          :error="errors[0]"
          :new-option="field.options.allowToAddNewOptions"
          :options="predefinedSelectOptions"
          :is-readonly="readOnly"
          page="repository"
          @input="handleInput"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- select (MASTER) -->

      <ValidationProvider
        v-if="
          field.dataType === 'SINGLE_SELECT' &&
          ['MASTER'].includes(field.options.optionsType)
        "
        v-slot="{ errors }"
        :name="field.name"
        :rules="{ required: field.isMandatory }"
        class="full-width"
      >
        <SelectField
          :value="value"
          :is-mandatory="field.isMandatory"
          :label="label || field.name"
          :error="errors[0]"
          :new-option="field.options.allowToAddNewOptions"
          :options="options"
          :is-readonly="readOnly"
          page="repository"
          @input="handleInput"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- boolean -->

      <ValidationProvider
        v-if="field.dataType === 'BOOLEAN'"
        v-slot="{ errors }"
        :name="field.name"
        :rules="{ required: field.isMandatory }"
        class="full-width"
      >
        <SingleChoiceField
          :value="value"
          :is-mandatory="field.isMandatory"
          :label="label || field.name"
          :options="booleanOptions"
          :error="errors[0]"
          :is-readonly="readOnly"
          @input="handleInput"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- omr -->

      <ValidationProvider
        v-if="field.dataType === 'OMR'"
        v-slot="{ errors }"
        :name="field.name"
        :rules="{ required: field.isMandatory }"
        class="full-width"
      >
        <SingleChoiceField
          :value="value"
          :is-mandatory="field.isMandatory"
          :label="label || field.name"
          :options="omrOptions"
          :error="errors[0]"
          :is-readonly="readOnly"
          @input="handleInput"
        />
      </ValidationProvider>

      <!-- ... -->
    </div>

    <!-- sync -->
    <!-- {{ field.isSync }} -->
    <div class="row items-center justify-center q-mt-sm">
      <BaseButton
        v-if="visiableSyncBtn"
        label="Sync"
        color="secondary"
        @click="$emit('saveSync', field)"
      />
    </div>
    <!-- ... -->
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import DateTimeField from "@/components/common/form/date-time-field/DateTimeField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import AsyncSelectField from "@/components/common/form/async-select-field/AsyncSelectField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import { axiosCrypto } from "@/api/axios.js";

export default {
  name: "RepositoryField",

  components: {
    ValidationProvider,
    TextField,
    TextAreaField,
    NumberField,
    DateField,
    TimeField,
    DateTimeField,
    SelectField,
    AsyncSelectField,
    SingleChoiceField,
  },

  props: {
    field: {
      type: Object,
      required: true,
    },

    repositoryId: {
      type: [String, Number],
      required: true,
    },

    value: {
      type: [String, Number],
      required: true,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "",
    },

    syncField: {
      type: Object,
      default: () => {},
    },

    parentControl: {
      type: Boolean,
      default: false,
    },

    rowIndex: {
      type: Number,
      required: true,
    },

    filter: {
      type: Number,
      default: 0,
    },

    freezedData: {
      type: Object,
      default: () => {},
    },

    showFreeze: {
      type: Boolean,
      default: false,
    },

    expiry: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      booleanOptions: [
        {
          id: this.$nano.id(),
          label: this.field.options.truthyValue,
          value: this.field.options.truthyValue,
        },
        {
          id: this.$nano.id(),
          label: this.field.options.falsyValue,
          value: this.field.options.falsyValue,
        },
      ],
      omrOptions: [
        {
          id: this.$nano.id(),
          label: "Yes",
          value: "true",
        },
        {
          id: this.$nano.id(),
          label: "No",
          value: "false",
        },
      ],
      visiableSyncBtn: false,
      options: [],
      freeze: false,
      freezeData: this.freezedData,
      freezed: false,
    };
  },

  computed: {
    predefinedSelectOptions() {
      return this.field.options.predefinedOptions.map((option) => ({
        id: this.$nano.id(),
        label: option,
        value: option,
      }));
    },

    apiPath() {
      return `/repository/${this.repositoryId}/uniqueColumnValues`;
    },
  },

  watch: {
    field: {
      immediate: true,
      async handler() {
        for (let key in this.syncField) {
          if (this.syncField[key] === this.field.name) {
            this.visiableSyncBtn = true;
          }
        }
      },
    },

    filter: {
      immediate: true,
      deep: true,
      handler() {
        if (this.field.filterBy) {
          if (this.field.options.masterFormId) {
            this.getMasterEntriesFilter(this.field);
          }
        }
      },
    },
  },

  created() {
    if (this.freezedData) {
      this.freezed = this.field.name in this.freezedData;
    }
    if (this.field.options.masterFormId) {
      this.getMasterEntries(
        this.field.options.masterFormId,
        this.field.options.masterTableColumn
      );
    }
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);

      if (this.parentControl) {
        this.$emit("filterBy", this.field, this.rowIndex);
      }

      if (this.freezed) {
        this.updateOrAddField(this.field.name, value);
      }
    },

    handleFreeze() {
      this.freezed = !this.freezed;
      if (this.freezed) {
        this.updateOrAddField(this.field.name, this.value);
        // this.$emit('update:freezedData',this.freezeData)
        this.$emit("update:freezed", this.freeze);
      } else {
        this.$emit("update:removeFreezed", this.field.name);
      }
    },

    updateOrAddField(field, value) {
      if (field in this.freezeData) {
        this.freezeData[field] = value;
      } else {
        this.freezeData = {
          ...this.freezeData,

          [field]: value,
        };
      }
      this.$emit("update:freezedData", this.freezeData);
    },

    async getMasterEntries(formId, column) {
      try {
        const response = await axiosCrypto.post(
          `/form/${formId}/uniqueColumnValues`,
          JSON.stringify({
            column: column,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }

        // this.options = data;
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
        }
        // let optVal = this.options.find((row) => row.label === this.value);
        // if (!optVal) {
        //   if (this.value) {
        //     this.options.push({
        //       id: this.$nano.id(),
        //       label: this.value,
        //       value: this.value,
        //     });
        //   }
        // }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getMasterEntriesFilter(field) {
      try {
        const response = await axiosCrypto.post(
          `/form/${field.options.masterFormId}/uniqueColumnValues`,
          JSON.stringify({
            column: field.options.masterTableColumn,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
            filters: field.filterBy,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.options = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
          if (this.options.length === 1) {
            //this.$emit("input", this.options[0].value);
            this.handleInput(this.options[0].value);
          }
          // this.$emit("clearFilter");
        } else {
          // if (this.value) {
          //   this.options = [
          //     {
          //       id: this.$nano.id(),
          //       label: this.value,
          //       value: this.value,
          //     },
          //   ];
          // }
          if (this.field.options.masterFormId) {
            this.getMasterEntries(
              this.field.options.masterFormId,
              this.field.options.masterTableColumn
            );
          }
          this.handleInput("");
          // this.$emit("clearFilter");
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#repository-field {
  display: flex;

  #checkbox-field {
    margin-top: 42px;
    cursor: pointer;
  }

  .full-width {
    width: 100%;
  }
}
</style>
