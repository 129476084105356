<template>
  <BaseActionButton
    v-tooltip.bottom="'columns'"
    is-flat
    no-border
    icon="mdi-table-column-plus-after"
  >
    <q-menu
      transition-show="scale"
      transition-hide="scale"
      anchor="bottom end"
      self="top right"
    >
      <!-- columns -->
      <div class="row border">
        <div class="title-3 q-pa-xs col-auto">
          <input
            ref="searchQuery"
            v-model="searchColumns"
            type="text"
            class="q-pa-md"
            placeholder="Show/Hide Columns"
            autofocus
            @keyup="searchColumnList(searchColumns)"
          />
        </div>
        <div v-if="repositoryFields && columns.length" class="col-auto q-pa-xs">
          <BaseButton label="Apply" color="primary" @click="apply" />
        </div>
      </div>
      <BaseScrollbar :height="scrollbarHeight" class="q-pb-sm">
        <ListItem
          v-for="column in _columns"
          :key="column.id"
          :icon="
            column.isVisible
              ? 'mdi-checkbox-marked'
              : 'mdi-checkbox-blank-outline'
          "
          :class="column.display"
          :is-selected="column.isVisible"
          :label="column.label"
          @click="updateColumns(column)"
        />
      </BaseScrollbar>

      <!-- ... -->
    </q-menu>
  </BaseActionButton>
</template>

<script>
import ListItem from "@/components/common/ListItem.vue";
import { menu } from "@/api/factory.js";

export default {
  name: "ToggleColumns",

  components: { ListItem },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    repositoryFields: {
      type: Boolean,
      default: false,
    },

    toggleId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      filterColumns: [],
      columnList: "",
      searchColumns: "",
    };
  },
  computed: {
    _columns() {
      return this.columns.filter((column) => column.type === "NORMAL");
    },

    scrollbarHeight() {
      const height = this._columns.length * 36;
      return height > 216 ? "276px" : `${height + 58}px`;
    },
  },

  methods: {
    updateColumns1(columnId) {
      const columns = this.columns;
      const columnIdx = columns.findIndex((column) => column.id === columnId);

      columns[columnIdx].isVisible = !columns[columnIdx].isVisible;
      this.$emit("update:columns", columns);
    },

    apply() {
      this.filterColumns = [];
      console.log(this.columns);
      this.columns.forEach((field) => {
        if (field.isVisible === false) {
          this.filterColumns.push({
            Fieldid: field.fieldId,
            value: field.isVisible,
          });
        }
      });
      console.log(this.filterColumns);
      this.$emit("viewColumns", this.filterColumns);
      this.filterColumns = [];
    },

    searchColumnList(value) {
      this.columns.forEach((column) => {
        column.display = "";
        if (value) {
          if (!column.label.toLowerCase().includes(value.toLowerCase())) {
            column.display = "hide";
          }
        }
      });
    },

    async updateColumns(field) {
      if (this.toggleId) {
        const columnIdx = this.columns.findIndex(
          (column) => column.id === field.id
        );
        this.columns[columnIdx].isVisible = !this.columns[columnIdx].isVisible;
        let input = { id: field.hideId || 0, columnName: field.id };

        const { error, payload } = await menu.updateHideColumns(
          this.toggleId,
          input
        );

        if (error) {
          this.$alert.error(error);
          return;
        }
        let hideCols = [];
        if (field.hideId) {
          hideCols = this.columns.filter((col) => col.id != field.hideId);
        } else {
          hideCols = this.columns;
          hideCols.push({
            id: payload,
            columnName: field.id,
          });
        }
        this.columns[columnIdx].hideId = field.hideId ? 0 : payload;
        this.$emit("update:columns", hideCols);
      } else {
        const columns = this.columns;
        const columnIdx = columns.findIndex((column) => column.id === field.id);

        columns[columnIdx].isVisible = !columns[columnIdx].isVisible;
        this.$emit("update:columns", columns);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.q-menu .border {
  border: 1px solid var(--divider-color) !important;
}

#list-item.hide {
  display: none !important;
}
</style>
