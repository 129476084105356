<template>
  <Confirm
    :value="value"
    :icon="header.icon || ''"
    icon-color="primary"
    width="450px"
    @input="closeModal"
  >
    <!-- title -->

    <template #title>{{ header?.label }}</template>

    <!-- ... -->

    <template #default>
      <ValidationObserver ref="form">
        <!-- field -->

        <ValidationProvider
          v-slot="{ errors }"
          name="field"
          :rules="{ required: true }"
        >
          <TextField
            v-model="pages"
            label="Pages"
            is-mandatory
            :error="errors[0]"
            class="q-mt-lg"
            placeholder="enter page number like 2,3,4  or 2-5"
          />
        </ValidationProvider>

        <div class="description q-mb-lg q-mt-sm">
          Note: First page and last page cannot be hide
        </div>

        <!-- ... -->
      </ValidationObserver>
    </template>

    <!-- footer -->

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="closeModal" />

      <BaseButton label="save" />
    </template>

    <!-- ... -->
  </Confirm>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Confirm from "@/components/common/popup/Confirm.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";

export default {
  name: "HidePage",

  components: {
    Confirm,
    ValidationObserver,
    ValidationProvider,
    TextField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    header: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pages: "",
    };
  },

  methods: {
    closeModal() {
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  @extend .text-xs;
  color: var(--icon-color);
}
</style>
