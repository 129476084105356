<template>
  <div
    :id="tabId"
    class="tab"
    :class="{ active: isActive, 'is-full-width': isFullWidth }"
    :style="{ color: isActive ? _color : '' }"
    v-on="$listeners"
  >
    {{ label }}
  </div>
</template>

<script>
export default {
  name: "Tab",

  props: {
    tabId: {
      type: String,
      default: "tab",
    },
    label: {
      type: String,
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    isFullWidth: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: "primary",
    },
  },

  computed: {
    _color() {
      return `var(--${this.color})`;
    },
  },
};
</script>

<style lang="scss" scoped>
#tabs .tab {
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 24px;
  height: 56px;
  display: flex;
  align-items: center;
  transition: all 0.25s ease;
  color: var(--title-3-color);

  &:hover {
    cursor: pointer;
    color: currentColor;
  }

  &.active,
  &.active:hover {
    cursor: default;
    color: currentColor;
  }

  &.is-full-width {
    flex: 1;
    margin: 0px;
    justify-content: center;
  }
}
</style>
