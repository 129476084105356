<template>
  <div id="kanban-view">
    <!-- <BaseActionButton
      v-if="kanbanView"
      v-tooltip.bottom="'Kanban View'"
      is-flat
      no-border
      :icon="activeView === 'KANBAN' ? 'mdi-grid' : 'mdi-grid'"
      :color="activeView === 'KANBAN' ? 'secondary' : 'gray'"
      @click="updateActiveView('KANBAN')"
    /> -->

    <BaseActionButton
      v-if="kanbanView"
      v-tooltip.bottom="'Kanban View'"
      is-flat
      :icon="activeKanban ? 'mdi-grid' : 'mdi-grid'"
      :color="activeKanban ? 'secondary' : 'gray'"
      no-border
      @click="$emit('clickKanban')"
    >
      <q-menu
        v-if="kanbanList.length"
        transition-show="scale"
        transition-hide="scale"
        anchor="bottom end"
        self="top right"
      >
        <BaseScrollbar :height="scrollbarHeight" width="240px" class="q-pb-sm">
          <!-- columns -->

          <!-- <div class="title-3 q-pa-md">Group By</div> -->

          <ListItem
            v-for="column in kanbanList"
            :key="column.id"
            :icon="
              column.name === activeView
                ? 'eva-radio-button-on'
                : 'eva-radio-button-off'
            "
            :is-selected="column.name === activeView"
            :label="column.label"
            @click="selectKanban(column.name)"
          />
        </BaseScrollbar>

        <!-- ... -->
      </q-menu>
    </BaseActionButton>

    <DefaultView
      :value="showDefaultView"
      :view="defaultView"
      @handleYes="handleYes"
      @handleNo="handleNo"
      @hideSheet="showDefaultView = false"
    />
  </div>
</template>

<script>
import ListItem from "@/components/common/ListItem.vue";
import DefaultView from "./DefaultView.vue";
import { profile } from "@/api/factory.js";
import store from "@/store/index.js";

export default {
  name: "KanbanView",

  components: { ListItem, DefaultView },

  props: {
    activeView: {
      type: String,
      default: "",
    },

    kanbanView: {
      type: Boolean,
      default: false,
    },

    kanbanList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      showDefaultView: false,
      defaultView: "",
      userId: this.$store.state.session.id,
      viewAlreadyAdded: true,
      options: {},
      defaultViewId: "",
    };
  },

  computed: {
    scrollbarHeight() {
      const height = this.kanbanList.length * 36;
      return height > 216 ? "276px" : `${height + 58}px`;
    },

    activeKanban() {
      if (
        this.activeView !== "LIST" &&
        this.activeView !== "GRID" &&
        this.activeView !== "CHART" &&
        this.activeView !== "TABLE"
      ) {
        return true;
      }
      return false;
    },
  },

  created() {
    this.getDefaultView();
  },

  // methods: {
  //   updateActiveView(activeView) {
  //     this.$emit("update:activeKanbanView", activeView);
  //   },

  //   selectKanban(activeView) {
  //     this.$emit("update:activeKanbanView", activeView);
  //   },
  // },

  methods: {
    selectKanban(activeView) {
      // console.log(activeView);
      let view = this.kanbanList.find((item) => item.name === activeView);
      console.log(view);
      if (view) this.defaultView = view.label;
      this.defaultViewId = activeView;
      this.showDefaultView = true;

      // this.$emit("update:activeView", activeView);
    },

    handleYes() {
      // this.$emit("update:activeView", this.defaultViewId);
      this.showDefaultView = false;
      if (this.viewAlreadyAdded) {
        this.updateDefaultView();
      } else {
        this.addDefaultView();
      }
    },

    handleNo() {
      this.$emit("update:activeKanbanView", this.defaultViewId);
      this.showDefaultView = false;
    },

    async addDefaultView() {
      this.$store.commit("showLoadingBar");

      let menu = this.$route.meta.menu;
      let input = {
        preference: {
          [menu]: [
            {
              menu: this.$route.meta.menu,
              subMenu: this.$route.meta.breadcrumb,
              view: this.defaultViewId,
            },
          ],
        },
      };
      const { error, payload } = await profile.addProfilePreference(input);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error("Error fetching changing view");
        return;
      }

      store.commit("setDefaultView", input.preference);
      store.commit("setPreferenceId", payload);
      this.viewAlreadyAdded = true;
      this.options = input;

      this.$emit("update:activeKanbanView", this.defaultViewId);
      //this.$alert.success("Default view added");
    },

    async updateDefaultView() {
      this.$store.commit("showLoadingBar");

      let menu = this.$route.meta.menu;
      let subMenu = this.$route.meta.breadcrumb;
      let input = {};
      if (this.options[menu]) {
        if (this.options[menu].length) {
          let index = this.options[menu].findIndex((item) => {
            return item.subMenu === subMenu;
          });
          // return;
          if (index >= 0) {
            this.options[menu][index].view = this.defaultViewId;
          } else {
            this.options[menu].push({
              menu: this.$route.meta.menu,
              subMenu: this.$route.meta.breadcrumb,
              view: this.defaultViewId,
            });
          }
        } else {
          this.options[menu] = [];
          this.options[menu].push({
            menu: this.$route.meta.menu,
            subMenu: this.$route.meta.breadcrumb,
            view: this.defaultViewId,
          });
        }
      } else {
        this.options[menu] = [];
        this.options[menu].push({
          menu: this.$route.meta.menu,
          subMenu: this.$route.meta.breadcrumb,
          view: this.defaultViewId,
        });
      }
      input = { preference: this.options };
      const { error } = await profile.updateProfilePreference(
        this.$store.state.preferenceId,
        input
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error("Error fetching changing view");
        return;
      }

      store.commit("setDefaultView", input.preference);

      this.$emit("update:activeKanbanView", this.defaultViewId);
      //this.$alert.success("Default view added");
    },

    async getDefaultView() {
      const { error, payload } = await profile.getProfilePreference(
        this.userId
      );

      if (error) {
        this.$alert.error("Error fetching get default view");
        return;
      }

      // console.log(payload);
      if (!payload) {
        this.viewAlreadyAdded = false;
      } else {
        this.options = JSON.parse(payload.options);
        store.commit("setDefaultView", this.options);
        store.commit("setPreferenceId", payload.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#kanban-view {
  display: flex;
  align-items: center;
}
</style>
