<template>
  <div id="dashboard-view">
    <BaseActionButton
      v-for="view in _views"
      :key="view.id"
      v-tooltip.top="view.label"
      is-flat
      no-border
      :icon="dashboardView === view.value ? view.activeIcon : view.icon"
      :color="dashboardView === view.value ? 'secondary' : 'gray'"
      @click="updateDashboardView(view.value)"
    />
  </div>
</template>

<script>
export default {
  name: "DashboardView",

  props: {
    dashboardView: {
      type: String,
      default: "CHART",
      validator(value) {
        return ["CHART", "TABLE", "SANKEY", "KANBAN"].includes(value);
      },
    },

    chartView: {
      type: Boolean,
      default: false,
    },

    tableView: {
      type: Boolean,
      default: true,
    },

    page: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      views: [
        {
          id: this.$nano.id(),
          label: "Table View",
          value: "TABLE",
          icon: "mdi-table",
          activeIcon: "mdi-table",
        },
        {
          id: this.$nano.id(),
          label: "Chart View",
          value: "CHART",
          icon: "eva-image",
          activeIcon: "eva-image",
        },
        {
          id: this.$nano.id(),
          label: "Sankey View",
          value: "SANKEY",
          icon: "mdi-chart-sankey-variant",
          activeIcon: "mdi-chart-sankey-variant",
        },
      ],
    };
  },

  computed: {
    _views() {
      if (this.page === "REPORT") {
        return this.views.slice(0, 2);
      }
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return this.views.slice(1, 2);
      }
      return this.views.slice(1, 3);
    },
  },

  methods: {
    updateDashboardView(activeView) {
      this.$emit("update:activeDashboardView", activeView);
    },
  },
};
</script>

<style lang="scss" scoped>
#dashboard-view {
  display: flex;
  align-items: center;
}
</style>
