<template>
  <Confirm
    :value="value"
    :icon="header.icon || ''"
    icon-color="primary"
    width="450px"
    @input="closeModal"
  >
    <!-- title -->

    <template #title>{{ header?.label }}</template>

    <!-- ... -->

    <template #default>
      <ValidationObserver ref="form">
        <!-- field -->

        <ValidationProvider
          v-slot="{ errors }"
          name="field"
          :rules="{ required: true }"
        >
          <TextAreaField
            v-model="checkInOut"
            :label="
              header.id === 'CheckIn'
                ? 'Purpose for checking in'
                : 'Purpose for checking out'
            "
            is-mandatory
            :error="errors[0]"
            class="q-my-lg"
          />
        </ValidationProvider>

        <!-- ... -->
      </ValidationObserver>
    </template>

    <!-- footer -->

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="closeModal" />

      <BaseButton label="save" @click="saveCheckInCheckOut" />
    </template>

    <!-- ... -->
  </Confirm>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Confirm from "@/components/common/popup/Confirm.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import { repository } from "@/api/factory.js";
export default {
  name: "CheckedInOut",

  components: {
    Confirm,
    ValidationObserver,
    ValidationProvider,
    TextAreaField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    header: {
      type: Object,
      required: true,
    },

    file: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      checkInOut: "",
    };
  },

  methods: {
    closeModal() {
      this.$emit("close", false);
    },

    async saveCheckInCheckOut() {
      this.$store.commit("showLoadingBar");
      let input = {
        comments: this.checkInOut,
      };

      if (this.header.label === "CheckOut") {
        const { error, payload } = await repository.fileCheckOut(
          this.file.repositoryId,
          this.file.id,
          input
        );

        this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }

        if (!payload) {
          return;
        }
        console.log(payload);
        this.$emit("checkout", false);
        this.$emit("downloadFile", "");
      }
      // else {
      //   const { error, payload } = await repository.fileCheckOut(
      //     this.file.repositoryId,
      //     this.file.id,
      //     input
      //   );

      //   this.$store.commit("hideLoadingBar");

      //   if (error) {
      //     this.$alert.error(error);
      //     return;
      //   }

      //   if (!payload) {
      //     return;
      //   }
      //   console.log(payload);
      //   this.$alert.success("file checkin successfully");
      // }

      this.checkInOut = "";
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
