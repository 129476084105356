<template>
  <BaseActionButton
    v-tooltip.bottom="'group'"
    is-flat
    icon="eva-copy-outline"
    no-border
  >
    <q-menu
      transition-show="scale"
      transition-hide="scale"
      anchor="bottom end"
      self="top right"
    >
      <BaseScrollbar :height="scrollbarHeight" width="240px" class="q-pb-sm">
        <!-- columns -->

        <div class="title-3 q-pa-md">Group By</div>

        <ListItem
          v-for="column in _columns"
          :key="column.id"
          :icon="
            column.name === groupBy
              ? 'eva-radio-button-on'
              : 'eva-radio-button-off'
          "
          :is-selected="column.name === groupBy"
          :label="column.label"
          @click="updateGroupBy(column.name)"
        />
      </BaseScrollbar>

      <!-- ... -->
    </q-menu>
  </BaseActionButton>
</template>

<script>
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "GroupItems",

  components: { ListItem },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    groupBy: {
      type: String,
      required: true,
    },
  },

  computed: {
    _columns() {
      return [
        {
          id: this.$nano.id(),
          label: "None",
          name: "",
        },
        ...this.columns.filter((column) => column.isGroupable),
      ];
    },

    scrollbarHeight() {
      const height = this._columns.length * 36;
      return height > 216 ? "276px" : `${height + 58}px`;
    },
  },

  methods: {
    updateGroupBy(groupBy) {
      this.$emit("update:groupBy", groupBy);
    },
  },
};
</script>

<style lang="scss" scoped></style>
