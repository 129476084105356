<template>
  <BaseActionButton
    v-tooltip.top="'indent'"
    is-flat
    icon="mdi-format-indent-increase"
  >
    <q-menu
      anchor="bottom middle"
      self="top middle"
      :offset="[0, 8]"
      transition-show="scale"
      transition-hide="scale"
    >
      <BaseActionButton
        v-for="indent in indents"
        :key="indent.id"
        v-tooltip.right="indent.label"
        is-flat
        :icon="indent.icon"
        class="q-ma-sm"
        @click="$emit(indent.value)"
      />
    </q-menu>
  </BaseActionButton>
</template>

<script>
export default {
  name: "Indent",

  data() {
    return {
      indents: [
        {
          id: this.$nano.id(),
          label: "increase indent",
          icon: "mdi-format-indent-increase",
          value: "increase",
        },
        {
          id: this.$nano.id(),
          label: "decrease indent",
          icon: "mdi-format-indent-decrease",
          value: "decrease",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
