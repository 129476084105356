<template>
  <BaseActionButton
    v-tooltip.top="'list'"
    is-flat
    icon="mdi-format-list-bulleted-square"
  >
    <q-menu
      anchor="bottom middle"
      self="top middle"
      :offset="[0, 8]"
      transition-show="scale"
      transition-hide="scale"
    >
      <BaseActionButton
        v-for="list in lists"
        :key="list.id"
        v-tooltip.right="list.label"
        is-flat
        :icon="list.icon"
        class="q-ma-sm"
        @click="$emit(list.value)"
      />
    </q-menu>
  </BaseActionButton>
</template>

<script>
export default {
  name: "List",

  data() {
    return {
      lists: [
        {
          id: this.$nano.id(),
          label: "bullet list",
          icon: "mdi-format-list-bulleted",
          value: "bullet",
        },
        {
          id: this.$nano.id(),
          label: "number list",
          icon: "mdi-format-list-numbered",
          value: "number",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
