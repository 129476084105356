<template>
  <BaseActionButton
    v-tooltip.top="'alignment'"
    is-flat
    icon="mdi-format-align-center"
  >
    <q-menu
      anchor="bottom middle"
      self="top middle"
      :offset="[0, 8]"
      transition-show="scale"
      transition-hide="scale"
    >
      <BaseActionButton
        v-for="alignment in alignments"
        :key="alignment.id"
        v-tooltip.right="alignment.label"
        is-flat
        :icon="alignment.icon"
        class="q-ma-sm"
        @click="$emit('toggle', alignment.label)"
      />
    </q-menu>
  </BaseActionButton>
</template>

<script>
export default {
  name: "Alignment",

  data() {
    return {
      alignments: [
        {
          id: this.$nano.id(),
          label: "left",
          icon: "mdi-format-align-left",
        },
        {
          id: this.$nano.id(),
          label: "center",
          icon: "mdi-format-align-center",
        },
        {
          id: this.$nano.id(),
          label: "right",
          icon: "mdi-format-align-right",
        },
        {
          id: this.$nano.id(),
          label: "justify",
          icon: "mdi-format-align-justify",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
