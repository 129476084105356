<template>
  <div id="file-list">
    <!-- header -->

    <div
      class="header"
      :class="{ 'has-selected-files': hasSelectedFiles }"
      @click="toggleContent"
    >
      <div class="label name-and-files">
        <slot name="label"></slot>
        <span class="q-ml-xs">({{ numberOfFiles }})</span>
      </div>

      <q-space />

      <div v-if="showAutoLoad" class="auto-reload">
        <p
          v-if="isContentMaximized"
          class="q-ml-sm text-gray ellipsis"
          style="font-size: 10px; margin-top: 15px"
        >
          auto-refresh every 15 seconds
        </p>
        <BaseActionButton
          v-if="isContentMaximized"
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-ml-sm"
          @click="refreshClicked()"
        />
      </div>

      <slot v-if="isContentMaximized" name="actions-maximized"></slot>
      <slot v-else name="actions-minimized"></slot>

      <BaseIcon
        name="mdi-menu-down"
        inherit-color
        class="icon"
        :class="{ 'rotate-180': isContentMaximized }"
      />
    </div>

    <!-- ... -->

    <!-- content -->

    <q-expansion-item :value="isContentMaximized" :header-style="_styles">
      <div v-if="hasFiles" class="q-pb-sm">
        <slot></slot>
      </div>

      <div v-else class="no-files">No files found</div>
    </q-expansion-item>

    <!-- ... -->
  </div>
</template>

<script>
export default {
  name: "FileList",

  props: {
    hasFiles: {
      type: Boolean,
      default: false,
    },

    hasSelectedFiles: {
      type: Boolean,
      default: false,
    },

    numberOfFiles: {
      type: Number,
      default: 0,
    },

    showAutoLoad: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      reloadClicked: false,
      isContentMaximized: this.hasFiles,
    };
  },

  computed: {
    _styles() {
      return {
        minHeight: "0px",
        height: "0px",
        padding: "0px",
        overflow: "hidden",
      };
    },
  },

  watch: {
    hasFiles(newVal) {
      this.isContentMaximized = newVal;
    },
  },

  mounted() {
    this.startAutoRefresh();
  },

  beforeDestroy() {
    this.stopAutoRefresh();
  },

  methods: {
    toggleContent() {
      if (!this.reloadClicked) {
        this.isContentMaximized = !this.isContentMaximized;
      } else {
        this.reloadClicked = false;
      }
    },
    showContent() {
      this.isContentMaximized = this.hasFiles;
    },

    refreshClicked() {
      this.reloadClicked = true;

      this.getdata();
    },

    getdata() {
      if (this.isContentMaximized) {
        this.$emit("getFiles");
      }
    },

    startAutoRefresh() {
      this.autoRefreshInterval = setInterval(() => {
        this.getdata();
      }, 15000);
    },

    stopAutoRefresh() {
      if (this.autoRefreshInterval) {
        clearInterval(this.autoRefreshInterval);

        this.autoRefreshInterval = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#file-list {
  background-color: var(--component-bg-color);
  border-radius: 4px;
  position: relative;

  .header {
    display: flex;
    align-items: center;
    height: 68px;
    padding: 16px;

    &:hover {
      cursor: pointer;
    }

    &.has-selected-files::after {
      content: "";
      position: absolute;
      left: 0;
      height: 36px;
      width: 4px;
      background-color: var(--secondary);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .label {
      //flex: 1;
      color: var(--icon-color);
      font-weight: 500;
      font-size: 13px;
      text-transform: capitalize;
    }

    .name-and-files {
      display: flex;
    }

    .auto-reload {
      display: flex;
      align-items: center;
    }

    .icon {
      color: var(--icon-color-inverted);
      transition: all 0.3s ease;
    }
  }

  .no-files {
    color: var(--icon-color);
    text-align: center;
    padding: 0px 16px 16px;
  }
}
</style>
