<template>
  <FileList :has-files="!!files.length" class="q-mb-md">
    <template #label>Uploading</template>

    <template v-if="files.length" #actions-minimized>
      <Pulse color="light-green" class="q-mr-md" />
    </template>

    <template #default>
      <FileListItem
        v-for="(file, index) in files"
        :key="file.id"
        :file="file"
        class="file"
      >
        <q-circular-progress
          v-if="showProgress"
          show-value
          font-size="13px"
          size="48px"
          color="light-green"
          :track-color="trackColor"
          :thickness="0.22"
          :value="file.uploadedPercentage"
          :indeterminate="!file.uploadedPercentage"
          class="font-semibold"
        >
          {{ file.uploadedPercentage }}%
        </q-circular-progress>

        <BaseActionButton
          v-if="showDeleteBtn"
          v-tooltip:red.top="'Delete'"
          is-flat
          color="red"
          icon="eva-trash-2-outline"
          class="q-mr-md"
          @click.stop="$emit('delete', index)"
        />
      </FileListItem>
    </template>
  </FileList>
</template>

<script>
import FileList from "@/views/upload-and-index/components/FileList.vue";
import FileListItem from "@/views/upload-and-index/components/FileListItem.vue";
import Pulse from "@/components/common/Pulse.vue";
import { mapState } from "vuex";

export default {
  name: "UploadingFileList",

  components: { FileList, FileListItem, Pulse },

  props: {
    files: {
      type: Array,
      required: true,
    },

    showProgress: {
      type: Boolean,
      default: true,
    },

    showDeleteBtn: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(["darkTheme"]),

    trackColor() {
      return this.darkTheme ? "gray-8" : "gray-3";
    },
  },
};
</script>

<style lang="scss" scoped>
.file:not(:last-child) {
  border-bottom: 1px solid var(--divider-color);
}
</style>
