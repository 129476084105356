<template>
  <div id="repoitoryOverview">
    <Portal to="action-bar">
      <keep-alive>
        <ItemActions
          :id="repositoryId"
          :columns="[]"
          group-by=""
          order=""
          :filter-by="[]"
          criteria=""
          active-view=""
          :simple-search-filter="true"
          :hide-actions="[
            'toggle',
            'sort',
            'group',
            'filter',
            'activeView',
            'export',
            'search',
          ]"
          @refresh="refreshData"
          @filter="getchart"
        />
      </keep-alive>
    </Portal>

    <div class="gridwrapper">
      <!-- <RepositoryFilter
        :repository-details="repositoryDetails"
        @updateRepositoryId="getRepositoryOverView"
      ></RepositoryFilter> -->
      <SimpleFilter
        :id.sync="repositoryId"
        :workspace-id.sync="workspaceId"
        :columns="[]"
        :filter-by.sync="filterBy"
        :workspace="true"
        name="Select Folder"
        :workspace-list="workspaceList"
        :list="repositoryDetails"
        module="repository"
        :module-id="repositoryId"
        active-view=""
        :date-value="true"
        :week-value="weekValue"
        :month-value="monthValue"
        :year-value="yearValue"
        :no-filter="true"
        @update:id="getRepositoryOverView"
      />

      <BaseScrollbar height="calc(100vh - 250px)">
        <div
          class="col-12"
          style="
            border-radius: 10px;
            padding: 0px 15px 0px 15px;
            margin-top: 10px;
          "
        >
          <DetailsGrid
            :id="repositoryId"
            :credits="credits"
            :data="data"
            :workspace-id="workspaceId"
            :recent-activity="recentActivity"
            :dashboard="totalfilesPin"
            :recent="dashboard"
            @issavesheet="saveFilter('TotalFiles')"
            @pinRecentActivity="saveFilter('recentActivity')"
            @isDeleteRecent="deleteItem('recentActivity')"
            @isDelete="deleteItem('TotalFiles')"
          ></DetailsGrid>

          <!-- <RecentGrid></RecentGrid> -->
        </div>
        <div
          class="col-12"
          style="
            border-radius: 10px;
            padding: 0px 20px 0px 20px;
            margin-top: 10px;
          "
        >
          <OverviewGrid
            :credits="credits"
            :chart-view="chartView"
            :dashboard="uploadPin"
            @chart="chart"
            @pinRecentActivity="saveFilter('uploadFiles')"
            @isDelete="deleteItem('uploadFiles')"
          ></OverviewGrid>

          <!-- <RecentGrid></RecentGrid> -->
        </div>

        <UnpinDashboard
          v-model="isDeleteConfirmationVisible"
          :label="deleteName"
          :delete-data="deleteData"
          @delete="success = true"
        />

        <div
          v-if="chartView"
          id="chart"
          class="col-12 q-pr-sm q-mb-sm"
          style="padding: 0px 17px 0px 12px; margin-top: 5px"
        >
          <div class="chartview">
            <div class="row item-center q-body q-mb-sm">
              <div
                class="col q-mr-xs cursor-pointer header"
                :class="chartDataDrilldownStage.month ? 'text-secondary' : ''"
                @click="
                  chartDataDrilldownStage.month = '';
                  chartDataDrilldownStage.week = '';
                  getOcrType();
                "
              >
                <strong
                  >{{ weekValue }} {{ monthValue }} {{ yearValue }}
                </strong>
              </div>
              <div class="col-auto q-pa-sm">
                <BaseActionButton
                  icon="mdi-pin"
                  :color="chartpin ? 'secondary' : 'gray'"
                  no-border
                  is-flat
                  size="20px"
                  rotate="rotate-45"
                  @click="
                    chartpin
                      ? deleteItem('repositoryChart')
                      : saveFilter('repositoryChart')
                  "
                />
              </div>
              <div
                v-if="chartDataDrilldownStage.month"
                class="col-auto cursor-pointer header"
                :class="chartDataDrilldownStage.week ? 'text-secondary' : ''"
                @click="
                  chartDataDrilldownStage.week = '';
                  getOcrType(chartDataDrilldownStage.month);
                "
              >
                / {{ chartDataDrilldownStage.month }}
              </div>
              <div
                v-if="chartDataDrilldownStage.week"
                class="col-auto q-ml-xs header"
                @click="chartDataDrilldownStage.week = ''"
              >
                / {{ chartDataDrilldownStage.week }}
              </div>
            </div>
            <div>
              <vue-highcharts
                ref="refWorkflowChart"
                :highcharts="Highcharts"
                :options="chartData"
              ></vue-highcharts>
            </div>
          </div>
          <div v-if="chartView" class="body"></div>
        </div>
      </BaseScrollbar>
    </div>
  </div>
</template>

<script>
import DetailsGrid from "@/views/repositories/views/repository-overview/DetailsGrid";
import OverviewGrid from "@/views/repositories/views/repository-overview/OverviewGrid.vue";
import { Portal } from "portal-vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
import { repository, workspace, dashboard } from "@/api/factory.js";
import SimpleFilter from "@/components/common/display/item-actions/components/SimpleFilter.vue";
import VueHighcharts from "vue2-highcharts";
import Exporting from "highcharts/modules/exporting.js";
import Drilldown from "highcharts/modules/drilldown.js";
import Highcharts from "highcharts";
import UnpinDashboard from "@/components/common/display/item-actions/components/UnpinDashboard.vue";

Drilldown(Highcharts);
Exporting(Highcharts);
export default {
  name: "RepositoriesOverview",

  components: {
    DetailsGrid,
    Portal,
    ItemActions,
    OverviewGrid,
    SimpleFilter,
    VueHighcharts,
    UnpinDashboard,
  },

  data() {
    return {
      chartView: false,
      data: [
        {
          date: "24/11/2001",
          activity: "muthuperumal",
          description: "nothing",
        },
        {
          date: "24/11/2002",
          activity: "muthuperumal",
          description: "nothing",
        },
        {
          date: "24/11/2003",
          activity: "muthuperumal",
          description: "nothing",
        },
      ],
      dateFilter: [
        {
          id: this.$nano.id(),
          label: "Today",
          value: "today",
        },
        {
          id: this.$nano.id(),
          label: "Yesterday",
          value: "yesterday",
        },
        {
          id: this.$nano.id(),
          label: "Last week",
          value: "week",
        },
        {
          id: this.$nano.id(),
          label: "Last Month",
          value: "month",
        },
        {
          id: this.$nano.id(),
          label: "Last Quarter",
          value: "quarter",
        },
        {
          id: this.$nano.id(),
          label: "Last Year",
          value: "year",
        },
      ],
      credits: {},
      Highcharts: Highcharts,
      chartData: {
        chart: {
          type: "spline",
          height: "330px",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          title: {
            text: "Count",
          },
          labels: {
            formatter: function () {
              return this.value;
            },
          },
        },
        tooltip: {
          crosshairs: true,
          shared: true,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        colors: ["#d4a4dd", "#858084", "#9cf1f7"],
        plotOptions: {
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: (e) => {
                  e.stopPropagation();
                  if (e.point.name && e.point.y) {
                    if (this.chartDataDrilldownStage.month === "") {
                      this.chartDataDrilldownStage.month = e.point.name;
                      this.getOcrType(e.point.name);
                    } else if (this.chartDataDrilldownStage.week === "") {
                      this.chartDataDrilldownStage.week = e.point.name;
                      this.getOcrType(
                        this.chartDataDrilldownStage.month,
                        e.point.name.replace("Week ", "")
                      );
                    }
                  }
                },
              },
            },
          },
          spline: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [],
        drilldown: {},
      },
      chartDataDrilldownStage: { month: "", week: "" },
      dateValue: "",
      repositoryId: 0,
      repositoryd: "all",
      fromDate: "",
      todate: "",
      year: "",
      month: "",
      weekno: "",
      repositoryDetails: [],
      filterBy: [],
      yearValue: "",
      monthValue: "",
      weekValue: "",
      dates: "",
      quaterData: [],
      WeekNumber: "",
      workspaceList: [],
      workspaceId: 0,
      recentActivity: [],
      recentCount: 5,
      keyValue: "",
      listedData: [],
      dashboard: false,
      chartpin: false,
      uploadPin: false,
      totalfilesPin: false,
      success: false,
      typeValue: "",
      isDeleteConfirmationVisible: false,
      deleteData: {},
      deleteName: {},
    };
  },

  computed: {
    items() {
      return this.resultData.map(({ key, value }) => ({
        key: this.groupBy,
        value: key,
        data: value.map((repository) => ({
          id: repository.id,
          name: repository.name,
        })),
      }));
    },
  },

  watch: {
    workspaceId: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workspaceId) {
          this.getRepositories();
        }
      },
    },

    success: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.success) {
          await this.getFilterList();
          if (!this.repositoryId) {
            this.repositoryId = 0;
          }
          if (this.typeValue === "repositoryChart") {
            this.getOcrType();
            this.getRecendActivity();
            this.getData();
          } else if (this.typeValue === "recentActivity") {
            this.getOcrType();
            this.getRecendActivity();
            this.getData();
          } else if (this.typeValue === "TotalFiles") {
            this.getOcrType();
            this.getRecendActivity();
            this.getData();
          } else if (this.typeValue === "uploadFiles") {
            this.getOcrType();
            this.getRecendActivity();
            this.getData();
          }
        }
      },
    },
  },

  created() {
    this.getRepositoryOverView();
    this.getOcrType();
    this.getRepositories();
    this.getWorkspaces();
    this.getFilterList();
  },

  methods: {
    refreshData() {
      this.getRepositoryOverView();
      this.getOcrType();
      this.getData();
      this.getchart();
      this.getRepositories();
      this.getWorkspaces();
    },
    async getData() {
      this.uploadPin = false;
      this.totalfilesPin = false;
      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.name,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));

      if (this.repositoryId) {
        let extractedObjectsRepositoryId = List.filter(
          (item) =>
            item.filterJson.length > 0 &&
            item.filterJson[0].type === "uploadFiles" &&
            item.filterJson[0].uId === this.repositoryId &&
            item.filterJson[0].fromDate === this.fromDate &&
            item.filterJson[0].toDate === this.todate
        );
        if (extractedObjectsRepositoryId.length) {
          this.uploadPin = true;
        }
      } else {
        let extractedObjects = List.filter(
          (item) =>
            item.filterJson.length > 0 &&
            item.filterJson[0].type === "uploadFiles" &&
            !item.filterJson[0].uId
        );
        if (extractedObjects.length) {
          this.uploadPin = true;
        }
      }

      if (this.repositoryId) {
        let extractedObjectsRepositoryIdtotal = List.filter(
          (item) =>
            item.filterJson.length > 0 &&
            item.filterJson[0].type === "TotalFiles" &&
            item.filterJson[0].uId === this.repositoryId &&
            item.filterJson[0].fromDate === this.fromDate &&
            item.filterJson[0].toDate === this.todate
        );
        if (extractedObjectsRepositoryIdtotal.length) {
          this.totalfilesPin = true;
        }
      } else {
        let extractedObjectstotal = List.filter(
          (item) =>
            item.filterJson.length > 0 &&
            item.filterJson[0].type === "TotalFiles" &&
            !item.filterJson[0].uId
        );
        if (extractedObjectstotal.length) {
          this.totalfilesPin = true;
        }
      }

      const input = { fromDate: this.fromDate, toDate: this.todate };
      this.$store.commit("showLoadingBarPage");
      this.repositoryReportList = [];
      this.repositoryReportListData = [];
      this.list = [];
      const { error, payload } = await repository.getRepositoryOverView(
        this.repositoryId,
        input
      );
      this.credits = JSON.parse(payload.data);
      this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getchart(fromDate, toDate, keyValue) {
      this.chartpin = false;
      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.name,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));

      if (this.repositoryId) {
        let extractedObjectsrebositoryId = List.filter(
          (item) =>
            item.filterJson.length > 0 &&
            item.filterJson[0].type === "repositoryChart" &&
            item.filterJson[0].uId === this.repositoryId &&
            item.filterJson[0].fromDate === fromDate &&
            item.filterJson[0].toDate === toDate
        );
        if (extractedObjectsrebositoryId.length) {
          this.chartpin = true;
        }
      }
      this.fromDate = fromDate;
      this.todate = toDate;
      this.keyValue = keyValue;
      this.WeekNumber = new Date(this.fromDate).getDate();
      this.dates = new Date(this.todate).getDate();
      this.chartData.xAxis.categories.splice(0);
      let date;
      if (keyValue === "7days") {
        date = new Date(this.todate);
      } else if (keyValue === "quarter") {
        date = new Date(this.todate);
      } else if (keyValue === "week") {
        date = new Date(this.todate);
      } else {
        date = new Date(this.fromDate);
      }
      let tempDate = new Date(date);
      tempDate.setDate(1);
      let firstDayOfMonth = tempDate.getDay();
      let startOffset = 7 - firstDayOfMonth;
      tempDate.setDate(tempDate.getDate() + startOffset);
      let dayDifference = date.getDate() - tempDate.getDate();
      let weekNumber = Math.ceil((dayDifference + 1) / 7) + 1;
      this.weekno = weekNumber;
      this.getData();
      let year;
      let month;
      if (keyValue === "7days") {
        year = new Date(this.todate).getFullYear();
        month = new Date(this.todate).getMonth();
      } else if (keyValue === "quarter") {
        year = new Date(this.todate).getFullYear();
        month = new Date(this.todate).getMonth();
        date = new Date(this.todate);
      } else if (keyValue === "week") {
        year = new Date(this.todate).getFullYear();
        month = new Date(this.todate).getMonth();
        date = new Date(this.todate);
      } else {
        year = new Date(fromDate).getFullYear();
        month = new Date(fromDate).getMonth();
      }
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let monthName = months[month].toString();
      let input;
      if (keyValue === "Currentmonth") {
        (this.yearValue = "-" + year),
          (this.monthValue = monthName),
          (this.weekValue = ""),
          (input = { year: year, month: monthName, weekno: "" });
      } else if (keyValue === "month") {
        (this.yearValue = "-" + year),
          (this.monthValue = monthName),
          (this.weekValue = ""),
          (input = { year: year, month: monthName, weekno: "" });
      } else if (keyValue === "currentyear") {
        (this.yearValue = " Year -" + year),
          (this.monthValue = ""),
          (this.weekValue = ""),
          (input = { year: year, month: "", weekno: "" });
      } else if (keyValue === "year") {
        (this.yearValue = " Year -" + year),
          (this.monthValue = ""),
          (this.weekValue = ""),
          (input = { year: year, month: "", weekno: "" });
      } else if (keyValue === "today") {
        (this.yearValue = "-" + year),
          (this.monthValue = "-" + monthName),
          (this.weekValue = this.dates),
          (input = { year: year, month: monthName, weekno: this.weekno });
      } else if (keyValue === "yesterday") {
        (this.yearValue = "-" + year),
          (this.monthValue = "-" + monthName),
          (this.weekValue = +this.dates),
          (input = { year: year, month: monthName, weekno: this.weekno });
      } else if (keyValue === "7days") {
        (this.yearValue = this.dates + "-" + monthName + "-" + year),
          (this.monthValue =
            this.WeekNumber + "-" + monthName + "-" + year + "  " + "to"),
          (this.weekValue = ""),
          (input = { year: year, month: monthName, weekno: this.weekno });
      } else if (keyValue === "week") {
        (this.yearValue = this.dates + "-" + monthName + "-" + year),
          (this.monthValue =
            this.WeekNumber + "-" + monthName + "-" + year + "  " + "to"),
          (this.weekValue = ""),
          (input = { year: year, month: monthName, weekno: this.weekno });
      } else if (keyValue === "quarter") {
        (this.weekValue = ""),
          (this.monthValue = ""),
          (this.yearValue = year),
          (input = { year: year, month: "", weekno: "" });
      }
      this.columns = [];
      this.$store.commit("showLoadingBar");
      this.chartData.series = [];
      const { error, payload } = await repository.getOcrType(
        this.repositoryId || 0,
        input
      );
      this.loadingBarContent = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        let yAxis = [];
        let data = JSON.parse(payload.data);
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const currentMonthIndex = new Date(this.todate).getMonth();
        let advancedData = [];
        let manualData = [];
        let standardData = [];
        for (let i = 0; i < 3; i++) {
          let index = (currentMonthIndex - i + 12) % 12;
          let monthName = months[index];
          advancedData.push(Number(data.ADVANCED[monthName]) || 0);
          manualData.push(Number(data.MANUAL[monthName]) || 0);
          standardData.push(Number(data.STANDARD[monthName]) || 0);
        }
        advancedData.reverse();
        manualData.reverse();
        standardData.reverse();
        if (keyValue === "quarter") {
          yAxis.push({
            name: "Advanced",
            data: advancedData.map((item) => Number(item)),
          });

          yAxis.push({
            name: "Manual",
            data: manualData.map((item) => Number(item)),
          });
          yAxis.push({
            name: "Standard",
            data: standardData.map((item) => Number(item)),
          });
          this.chartData.series = yAxis;
          let lineCharts = this.$refs.refWorkflowChart;
          try {
            lineCharts.removeSeries();
          } catch (e) {
            //
          }

          const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          const currentMonthIndex = new Date(2024, 5, 30).getMonth();
          let beforeThreeMonths = [];
          for (let i = 0; i < 3; i++) {
            let index = (currentMonthIndex - i + 12) % 12;
            let monthName = months[index].slice(0, 3); //
            beforeThreeMonths.unshift(monthName);
          }
          this.chartData.xAxis.categories.push(...beforeThreeMonths);
          this.quaterData = this.chartData.xAxis.categories;
          (this.weekValue = this.quaterData[0] + ","),
            (this.monthValue = this.quaterData[1] + ","),
            (this.yearValue = this.quaterData[2] + ","),
            lineCharts.addSeries(this.chartData.series[0]);
          lineCharts.addSeries(this.chartData.series[1]);
          lineCharts.addSeries(this.chartData.series[2]);
        } else {
          yAxis.push({
            name: "Advanced",
            data: Object.values(data.ADVANCED).map(Number),
          });
          yAxis.push({
            name: "Manual",
            data: Object.values(data.MANUAL).map(Number),
          });
          yAxis.push({
            name: "Standard",
            data: Object.values(data.STANDARD).map(Number),
          });
          this.chartData.series = yAxis;
          let lineCharts = this.$refs.refWorkflowChart;
          try {
            lineCharts.removeSeries();
          } catch (e) {
            //
          }
          for (let [key, value] of Object.entries(data.ADVANCED)) {
            let ChartSeries = { key, value };

            this.chartData.xAxis.categories.push([ChartSeries.key.toString()]);
          }
          lineCharts.addSeries(this.chartData.series[0]);
          lineCharts.addSeries(this.chartData.series[1]);
          lineCharts.addSeries(this.chartData.series[2]);
        }
      }
    },

    async getRepositoryOverView(value) {
      this.uploadPin = false;
      this.totalfilesPin = false;
      this.repositoryId = value;
      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.name,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));

      if (this.repositoryId) {
        let extractedObjectsRepositoryId = List.filter(
          (item) =>
            item.filterJson.length > 0 &&
            item.filterJson[0].type === "uploadFiles" &&
            item.filterJson[0].uId === this.repositoryId &&
            item.filterJson[0].fromDate === this.fromDate &&
            item.filterJson[0].toDate === this.todate
        );
        if (extractedObjectsRepositoryId.length) {
          this.uploadPin = true;
        }
      } else {
        let extractedObjects = List.filter(
          (item) =>
            item.filterJson.length > 0 &&
            item.filterJson[0].type === "uploadFiles" &&
            !item.filterJson[0].uId
        );
        if (extractedObjects.length) {
          this.uploadPin = true;
        }
      }
      if (this.repositoryId) {
        let extractedObjectsRepositoryIdtotal = List.filter(
          (item) =>
            item.filterJson.length > 0 &&
            item.filterJson[0].type === "TotalFiles" &&
            item.filterJson[0].uId === this.repositoryId &&
            item.filterJson[0].fromDate === this.fromDate &&
            item.filterJson[0].toDate === this.todate
        );
        if (extractedObjectsRepositoryIdtotal.length) {
          this.totalfilesPin = true;
        }
      } else {
        let extractedObjectstotal = List.filter(
          (item) =>
            item.filterJson.length > 0 &&
            item.filterJson[0].type === "TotalFiles" &&
            !item.filterJson[0].uId
        );
        if (extractedObjectstotal.length) {
          this.totalfilesPin = true;
        }
      }

      if (this.repositoryId) {
        this.getRecendActivity();
      }
      this.getOcrType();
      this.repositoryId = value;
      this.columns = [];
      this.loadingBarContent = true;
      let currentDate = new Date();
      let firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        2
      );
      let formattedFirstDayOfMonth = firstDayOfMonth
        .toISOString()
        .split("T")[0];

      let lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        1
      );
      let formattedLastDayOfMonth = lastDayOfMonth.toISOString().split("T")[0];
      this.fromDate = formattedFirstDayOfMonth;
      this.todate = formattedLastDayOfMonth;
      const date = {
        fromDate: this.fromDate,
        toDate: this.todate,
      };
      const { error, payload } = await repository.getRepositoryOverView(
        this.repositoryId || 0,
        date
      );
      this.credits = JSON.parse(payload.data);
      this.loadingBarContent = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getOcrType() {
      this.chartpin = false;

      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.name,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));

      if (this.repositoryId) {
        const now = new Date();
        const currentMonth = now.getMonth() + 1;
        const currentYear = now.getFullYear();

        let extractedObjectsRepositoryId = List.filter((item) => {
          if (item.filterJson.length > 0 && item.filterJson[0].fromDate) {
            const dateString = item.filterJson[0].fromDate;
            const date = new Date(dateString);
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return (
              item.filterJson[0].type === "repositoryChart" &&
              item.filterJson[0].uId === this.repositoryId &&
              month === currentMonth &&
              year === currentYear
            );
          }
          return false;
        });
        if (extractedObjectsRepositoryId.length) {
          this.chartpin = true;
        }
      } else {
        let extractedObjects = List.filter(
          (item) =>
            item.filterJson.length > 0 &&
            item.filterJson[0].type === "repositoryChart" &&
            !item.filterJson[0].uId
        );
        if (extractedObjects.length) {
          this.chartpin = true;
        }
      }

      this.columns = [];
      this.$store.commit("showLoadingBar");
      this.chartData.series = [];
      let month = new Date().getMonth();
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      let monthName = months[month].toString();
      let input = {
        year: new Date().getFullYear(),
        month: monthName,
        weekno: "",
      };
      (this.yearValue = "-" + input.year),
        (this.monthValue = input.month),
        (this.weekValue = "");
      const { error, payload } = await repository.getOcrType(
        this.repositoryId || 0,
        input
      );
      this.loadingBarContent = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        let yAxis = [];

        let data = JSON.parse(payload.data);
        yAxis.push({
          name: "Advanced",
          data: Object.values(data.ADVANCED).map(Number),
        });
        yAxis.push({
          name: "Manual",
          data: Object.values(data.MANUAL).map(Number),
        });
        yAxis.push({
          name: "Standard",
          data: Object.values(data.STANDARD).map(Number),
        });
        this.chartData.series = yAxis;
        let lineCharts = this.$refs.refWorkflowChart;
        try {
          lineCharts.removeSeries();
        } catch (e) {
          //
        }
        for (let [key, value] of Object.entries(data.ADVANCED)) {
          let ChartSeries = { key, value };

          this.chartData.xAxis.categories.push([ChartSeries.key.toString()]);
        }
        lineCharts.addSeries(this.chartData.series[0]);
        lineCharts.addSeries(this.chartData.series[1]);
        lineCharts.addSeries(this.chartData.series[2]);
      }
    },

    async getWorkspaces() {
      this.workspaceList = [];
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workspace.getWorkspaceList();
      this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (!payload) {
        return;
      }
      this.workspaceList = payload.map((workspace) => ({
        id: this.$nano.id(),
        label: workspace.value,
        value: workspace.id,
      }));
    },

    async getRepositories() {
      if (this.workspaceId) {
        this.repositoryDetails = [];
        this.loadingBarContent = true;
        const { error, payload } = await repository.getRepositoryList(
          "workspaceId",
          this.workspaceId
        );
        this.loadingBarContent = false;
        if (error) {
          this.$alert.error(error);
          return;
        }
        if (!payload.length) {
          return;
        }
        this.repositoryDetails = payload.map((repository) => ({
          id: this.$nano.id(),
          label: repository.value,
          value: repository.id,
        }));
      }
    },

    async getRecendActivity() {
      this.dashboard = false;
      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.name,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));

      if (this.repositoryId) {
        let extractedObjectsRepositoryId = List.filter(
          (item) =>
            item.filterJson.length > 0 &&
            item.filterJson[0].type === "recentActivity" &&
            item.filterJson[0].uId === this.repositoryId
        );
        if (extractedObjectsRepositoryId.length) {
          this.dashboard = true;
        }
      }

      this.recentActivity = [];
      this.loadingBarContent = true;
      const { error, payload } = await repository.getRecendActivity(
        this.repositoryId || 0,
        this.recentCount
      );
      this.loadingBarContent = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      let activity = JSON.parse(payload.data);
      this.recentActivity = activity.data[0].value;
    },
    chart() {
      if (this.chartView === true) {
        this.chartView = false;
      } else if (this.chartView === false) {
        this.chartView = true;
      }
    },

    async saveFilter(type) {
      this.typeValue = type;
      this.success = false;
      let filter = JSON.stringify(this.filterBy);
      let filtering = [
        {
          uId: this.repositoryId,
          wId: this.workspaceId,
          type: type,
          fromDate: this.fromDate,
          toDate: this.todate,
          keyValue: this.keyValue,
          filters: filter,
        },
      ];
      let filterss = JSON.stringify(filtering);
      let userId = [this.$store.state.session.id];
      let name = "";
      let description = "";
      let now = new Date();
      let timestamp = now.toISOString();

      if (this.repositoryId) {
        const repositoryName = this.repositoryDetails.find(
          (repositoryNames) => repositoryNames.value === this.repositoryId
        );
        if (type === "TotalFiles") {
          name =
            repositoryName.label +
            "  " +
            "Total files Uploaded" +
            "  " +
            timestamp;
          description = "Total files Uploaded";
        } else if (type === "recentActivity") {
          name =
            repositoryName.label + "  " + "Recent Activity" + "  " + timestamp;
          description = "Recent Activity";
        } else if (type === "uploadFiles") {
          name =
            repositoryName.label + "  " + "Upload Files" + "  " + timestamp;
          description = "Uploaded Files";
        } else if (type === "repositoryChart") {
          name = repositoryName.label + "  " + "chart" + "  " + timestamp;
          description = "chart";
        }
      } else if (!this.repositoryId) {
        if (type === "TotalFiles") {
          name = "All" + "  " + "Total files Uploaded" + "  " + timestamp;
          description = "Total files Uploaded";
        } else if (type === "repositoryChart") {
          name = "All" + "  " + "chart" + "  " + timestamp;
          description = "chart";
        } else if (type === "uploadFiles") {
          name = "All" + "  " + "Upload Files" + "  " + timestamp;
          description = "Uploaded Files";
        }
      }
      let input = {
        name: name,
        description: description,
        filterJson: filterss,
        filterFrom: "repositoryOverview",
        pinDashboard: true,
        assignTo: JSON.stringify(userId),
      };
      const { error } = await dashboard.saveDashboard(input);
      if (error) {
        this.$alert.warning("The Name Already Registered ");
        return;
      }
      await this.getFilterList();
      this.success = true;
      this.$alert.success(name + "  " + "Pinned SuccessFully");
    },
    async getFilterList() {
      this.loadingBarContent = true;
      let userId = this.$store.state.session.id.toString();
      const { error, payload } = await dashboard.getDashboardData({
        sortBy: this.sortBy,
        groupBy: "",
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "filterFrom",
                condition: "IS_EQUALS_TO",
                value: "repositoryOverview",
              },
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: userId,
              },
            ],
          },
        ],
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });
      this.loadingBarContent = false;
      this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);
        return;
      }
      let data = payload.data[0].value;
      this.listedData = data;
    },

    deleteItem(type) {
      this.typeValue = type;
      this.success = false;

      let names = "";

      if (this.repositoryId) {
        const repositoryName = this.repositoryDetails.find(
          (repositoryNames) => repositoryNames.value === this.repositoryId
        );

        if (type === "TotalFiles") {
          names = repositoryName.label + "  " + "Total files Uploaded";
        } else if (type === "recentActivity") {
          names = repositoryName.label + "  " + "Recent Activity";
        } else if (type === "uploadFiles") {
          names = repositoryName.label + "  " + "Upload Files";
        } else if (type === "repositoryChart") {
          names = repositoryName.label + "  " + "chart";
        }
      } else if (!this.repositoryId) {
        if (type === "TotalFiles") {
          names = "All" + "  " + "Total files Uploaded";
        } else if (type === "repositoryChart") {
          names = "All" + "  " + "chart";
        } else if (type === "uploadFiles") {
          names = "All" + "  " + "Upload Files";
        }
      }
      this.deleteName = names;
      this.listedData.map((item) => {
        const parse = JSON.parse(item.filterJson);
        if (parse[0].uId) {
          let itemName = "";
          if (item.name) {
            itemName = this.removeTimestampFromString(item.name);
          }

          if (parse[0].uId === this.repositoryId && itemName === names) {
            this.isDeleteConfirmationVisible = true;
            this.deleteData = {
              id: item.id,
              name: item.name,
              description: item.description,
              filterJson: item.filterJson,
              filterFrom: item.filterFrom,
              createdAt: item.createdAt,
              modifiedAt: item.modifiedAt,
              createdBy: item.createdBy,
              modifiedBy: item.modifiedBy,
              isDeleted: true,
            };
          }
        } else if (!parse[0].uId) {
          let itemName = "";
          if (item.name) {
            itemName = this.removeTimestampFromString(item.name);
          }

          if (itemName === names) {
            this.isDeleteConfirmationVisible = true;
            this.deleteData = {
              id: item.id,
              name: item.name,
              description: item.description,
              filterJson: item.filterJson,
              filterFrom: item.filterFrom,
              createdAt: item.createdAt,
              modifiedAt: item.modifiedAt,
              createdBy: item.createdBy,
              modifiedBy: item.modifiedBy,
              isDeleted: true,
            };
          }
        }
      });
    },
    removeTimestampFromString(str) {
      return str
        .replace(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/, "")
        .trim();
    },
  },
};
</script>

<style lang="scss" scoped>
.gridwrapper {
  position: relative;
  height: calc(100vh - 208px);

  .heading {
    font-weight: bold;
    font-size: 20px;
    color: var(--title-1-color);
    text-transform: capitalize;
  }
  .body {
    color: #858084;
    width: 100%;
    height: 15px;
  }

  #chart {
    border-radius: 10px;
    padding-top: 10px;
    width: 100%;

    .chartview {
      width: 100%;
      background-color: var(--component-bg-color);
      border: 1px solid var(--divider-color);
      border-radius: 10px;
    }
    .header {
      padding-left: 30px;
      padding-top: 20px;
      color: #858084;
    }
  }
}
</style>
