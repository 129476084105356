<template>
  <div id="search-items" class="col">
    <div id="Simple-search">
      <div class="label" style="margin-left: 0px">
        <BaseActionButton
          v-tooltip.top="'Search By'"
          is-flat
          icon="mdi-filter-plus-outline"
          :color="activeFilter ? 'secondary' : 'gray'"
          no-border
        >
          <div v-if="filter"></div>

          <q-menu
            auto-close
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top left"
          >
            <div class="title-3 q-pa-xs border">
              <input
                ref="searchQuery"
                v-model="searchColumns"
                type="text"
                class="q-pa-md"
                placeholder="Type here to search"
                @click.stop=""
                @keyup="searchColumnList(searchColumns)"
              />
            </div>

            <BaseScrollbar
              :height="scrollbarHeight"
              width="240px"
              class="q-pb-sm"
            >
              <template v-for="column in columnList">
                <ListItem
                  v-if="
                    column.display && (column.isFilter === false ? false : true)
                  "
                  :key="column.id"
                  :icon="
                    column.selected
                      ? 'eva-radio-button-on'
                      : 'eva-radio-button-off'
                  "
                  :is-selected="column.selected"
                  :label="column.label"
                  @click="updateFilterBy(column.id)"
                />
              </template>
            </BaseScrollbar>
            <!-- ... -->
          </q-menu>
        </BaseActionButton>

        <div v-if="activeFilter" class="selectedLabel">
          <span class="text-bold">{{ selectedLabel }} :</span>
        </div>

        <input
          ref="searchQuery"
          v-model="searchInput"
          type="text"
          class="q-px-xs"
          :class="{
            'text-input-small': activeFilter,
            'text-input-big': !activeFilter,
          }"
          :placeholder="isdataTypeIsDate ? 'YYYY-MM-DD' : 'Search'"
          @keyup="emitKeyUp"
          @keypress.enter="updateSimpleSearch"
        />

        <BaseActionButton
          v-tooltip.top="'Search'"
          icon="eva-search"
          no-border
          is-flat
          @click="updateSimpleSearch"
        />

        <BaseActionButton
          v-if="activeFilter"
          v-tooltip.top="'clear'"
          icon="mdi-close-circle"
          no-border
          is-flat
          @click="clearSimpleSearch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "SimpleSearchWithFilter",
  components: { ListItem },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },

    filterBy: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filter: false,
      activeFilter: false,
      searchColumns: "",
      columnList: [],
      filterArray: [],
      searchInput: "",
      selectedLabel: "",
      FilterValue: "",
      isdataTypeIsDate: false,
      isSingleSelect: false,
    };
  },
  computed: {
    scrollbarHeight() {
      const height = this.columns.length * 36;
      return height > 216 ? "276px" : `${height + 58}px`;
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        if (!this.$route.query.repositoryId) {
          this.activeFilter = false;
          this.filterArray = [];
          this.searchInput = "";
          this.FilterValue = "";
        }
      },
    },

    filterBy: {
      deep: true,
      immediate: true,
      handler() {
        if (this.filterBy.length === 0) {
          this.activeFilter = false;
          // this.searchInput = "";
        }
      },
    },

    columns: {
      deep: true,
      immediate: true,
      handler() {
        this.columnList = [];
        this.columns.forEach((column) => {
          this.columnList.push({
            ...column,
            selected: false,
            display: "show",
          });
        });
      },
    },
  },
  methods: {
    emitKeyUp() {
      this.FilterValue = this.searchInput;
      this.$emit("keyup", this.searchInput);
    },

    updateSimpleSearch() {
      if (this.activeFilter) {
        if (this.filterArray.length === 0) {
          let filter = [];
          this.$emit("update", filter);
        } else {
          if (this.isSingleSelect) {
            let valueFilter = [];
            valueFilter[0] = this.FilterValue;
            this.filterArray[0].filters[0].value = JSON.stringify(valueFilter);
            this.filterArray[0].filters[0].arrayValue = valueFilter;
            this.$emit("click", this.filterArray);
            this.$emit("update", this.filterArray);
          } else {
            this.filterArray[0].filters[0].value = this.FilterValue;
            this.$emit("click", this.filterArray);
            this.$emit("update", this.filterArray);
          }
        }
      }
    },
    searchColumnList(value) {
      this.columnList.forEach((column) => {
        column.display = "show";
        if (!column.label.toLowerCase().includes(value.toLowerCase())) {
          column.display = "";
        }
      });
    },
    updateFilterBy(id) {
      this.filter = !this.filter;
      this.columnList.forEach((column) => {
        column.selected = false;
      });
      let selectedColumn = this.columnList.find((column) => column.id === id);

      if (selectedColumn) {
        selectedColumn.selected = true;
        this.activeFilter = true;
        this.selectedLabel = selectedColumn.label;
        let filter = [];

        if (selectedColumn.value == "None") {
          this.activeFilter = false;
        } else {
          let condition = "CONTAINS";
          if (selectedColumn.dataType === "DATE") {
            condition = "IS_EQUALS_TO";
            this.isdataTypeIsDate = true;
            this.isSingleSelect = false;
          }

          if (selectedColumn.dataType === "SINGLE_SELECT") {
            this.isSingleSelect = true;
            // condition = "IS_EQUALS_TO";
            filter = [
              {
                filters: [
                  {
                    criteria: selectedColumn.name,
                    criteriaArray: [selectedColumn.name],
                    condition: condition,
                    value: "",
                    arrayValue: "",
                    dataType: "SINGLE_SELECT",
                  },
                ],
                groupCondition: "",
              },
            ];
            this.filterArray = filter;
          } else {
            this.isSingleSelect = false;
            filter = [
              {
                filters: [
                  {
                    criteria: selectedColumn.name,
                    condition: condition,
                    value: "",
                    dataType: selectedColumn.dataType,
                  },
                ],
                groupCondition: "",
              },
            ];
          }
          this.filterArray = filter;
        }
      }
    },

    clearSimpleSearch() {
      this.columnList.forEach((column) => {
        column.selected = false;
      });
      this.isdataTypeIsDate = false;
      this.filterArray = [];
      this.searchInput = "";
      this.FilterValue = "";
      this.activeFilter = false;
      this.filter = !this.filter;
      this.$emit("update", this.filterArray);
    },
  },
};
</script>

<style lang="scss" scoped>
#search-items {
  .selected {
    color: #00bcd4 !important;
  }

  .notselect {
    color: #64748b !important;
  }

  #action-btn {
    width: 28px !important;
    height: 28px !important;
  }
}

.q-menu .border {
  border: 1px solid var(--divider-color) !important;
}

#Simple-search {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 0px 12px;
  color: var(--icon-color);
  border: 1px solid $gray;
  // background-color: var(--body-bg-color);

  .label {
    @extend .text-sm;
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 8px;
  }

  .selectedLabel {
    margin-left: 2px;
    max-width: 100px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .selectedLabel:hover {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
  }
  .text-input-small {
    min-width: 70px;
    max-width: 110px;
  }
  .text-input-big {
    min-width: 70px;
    max-width: 110px;
  }
}
</style>
