<template>
  <!-- ... -->

  <!-- content -->

  <div id="tip-tap-editor">
    <!-- toolbar -->

    <Toolbar
      :hide-tool-bar="hideToolBar"
      :form-columns="formColumns"
      @set-heading="setHeading"
      @unset-heading="unsetHeading"
      @toggle-alignment="toggleAlignment"
      @toggle-bold="toggleBold"
      @toggle-italic="toggleItalic"
      @toggle-underline="toggleUnderline"
      @toggle-bullet-list="toggleBulletList"
      @toggle-number-list="toggleNumberList"
      @increase-indent="increaseIndent"
      @decrease-indent="decreaseIndent"
      @set-text-color="setTextColor"
      @unset-text-color="unsetTextColor"
      @set-bg-color="setBgColor"
      @unset-bg-color="unsetBgColor"
      @undo="undo"
      @redo="redo"
      @set-link="setLink"
      @add-field="addField"
    />

    <!-- ... -->

    <!-- content -->

    <EditorContent :editor="editor" />

    <!-- ... -->
  </div>

  <!-- ... -->
</template>

<script>
import Toolbar from "./components/toolbar/Toolbar.vue";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import Placeholder from "@tiptap/extension-placeholder";
import FieldPlaceholder from "./components/field-placeholder/extension.js";
import Code from "@tiptap/extension-code";

export default {
  name: "TextEditor",

  components: {
    Toolbar,
    EditorContent,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },

    field: {
      type: Object,
      default: () => {},
    },

    hideToolBar: {
      type: Boolean,
      default: true,
    },

    formColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      editor: null,
      textContent: "",
    };
  },

  watch: {
    editor: {
      deep: true,
      handler() {
        if (this.value) {
          const parser = new DOMParser();
          const html = parser.parseFromString(
            this.editor.getHTML(),
            "text/html"
          );
          var textContent = "";
          //console.log(html.body.childNodes);
          html.body.childNodes.forEach((parentNode) => {
            // textContent += "<" + parentNode.localName + ">";
            // parentNode.childNodes.forEach((node) => {
            //   //console.log(node);
            //   if (node.nodeName === "FIELDPLACEHOLDER") {
            //     textContent += node.attributes["fieldtype"].value;
            //   } else if (node.nodeName === "#text") {
            //     textContent += node.textContent;
            //   } else {
            //     textContent += node.outerHTML;
            //   }
            // });
            // textContent += "</" + parentNode.localName + ">";

            let content = parentNode.outerHTML.replace(
              new RegExp('"></fieldplaceholder>', "g"),
              ""
            );
            let control = content.split('<fieldplaceholder fieldtype="');
            //console.log(control);
            textContent += control.join("");
          });

          //console.log(textContent);
          let editor = {
            text: this.editor.getHTML(),
            content: textContent,
          };
          this.$emit("input", editor);
        }
      },
    },
  },

  created() {
    this.initiateEditor();
  },

  beforeDestroy() {
    this.editor.destroy();
  },

  methods: {
    initiateEditor() {
      this.editor = new Editor({
        content: this.value.text,
        extensions: [
          StarterKit,
          Link,
          Underline,
          TextAlign.configure({
            types: ["heading", "paragraph"],
          }),
          TextStyle,
          Color,
          Highlight.configure({
            multicolor: true,
          }),
          Placeholder.configure({
            placeholder: "Start typing …",
          }),
          FieldPlaceholder,
          Text,
          Code,
        ],
      });
    },

    setHeading(level) {
      this.editor.chain().focus().toggleHeading({ level }).run();
    },

    unsetHeading() {
      this.editor.chain().focus().clearNodes().unsetAllMarks().run();
    },

    toggleAlignment(alignment) {
      this.editor.chain().focus().setTextAlign(alignment).run();
    },

    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },

    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },

    toggleUnderline() {
      this.editor.chain().focus().toggleUnderline().run();
    },

    toggleBulletList() {
      this.editor.chain().focus().toggleBulletList().run();
    },

    toggleNumberList() {
      this.editor.chain().focus().toggleOrderedList().run();
    },

    increaseIndent() {
      this.editor.chain().focus().sinkListItem("listItem").run();
    },

    decreaseIndent() {
      this.editor.chain().focus().liftListItem("listItem").run();
    },

    undo() {
      this.editor.chain().focus().undo().run();
    },

    redo() {
      this.editor.chain().focus().redo().run();
    },

    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (!url) {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },

    setTextColor(color) {
      this.editor.chain().focus().setColor(color).run();
    },

    unsetTextColor() {
      this.editor.chain().focus().unsetColor().run();
    },

    setBgColor(color) {
      this.editor.chain().focus().setHighlight({ color }).run();
    },

    unsetBgColor() {
      this.editor.chain().focus().unsetHighlight().run();
    },

    addField(fieldType) {
      const placeholder = `<FieldPlaceholder fieldId="${fieldType.id}" fieldType="${fieldType.value}">${fieldType.value}</FieldPlaceholder>`;
      this.editor.chain().focus().insertContent(placeholder).run();
    },
  },
};
</script>

<style lang="scss" scoped>
#tip-tap-editor {
  margin: 16px;
  border: 1px solid var(--divider-color);
  padding: 10px;
  border-radius: 4px;
}
</style>
