<template>
  <div id="workspace-list">
    <div class="title">Workspace</div>

    <div class="wrapper">
      <ValidationProvider
        v-slot="{ errors }"
        name="workspace"
        :rules="{ required: true }"
      >
        <SelectField
          :value="value"
          is-mandatory
          :is-clearable="false"
          :options="workspaces"
          is-readonly
          :error="errors[0]"
          @input="handleInput"
        />
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import { workspace } from "@/api/factory.js";
import { ValidationProvider } from "vee-validate";
import SelectField from "@/components/common/form/select-field/SelectField.vue";

export default {
  name: "WorkspaceList",

  components: { ValidationProvider, SelectField },

  props: {
    value: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      workspaces: [],
    };
  },

  created() {
    this.getWorkspaces();
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    /* api Functions */

    async getWorkspaces() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await workspace.getWorkspaceList();

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      this.workspaces = payload.map((workspace) => ({
        id: this.$nano.id(),
        label: workspace.value,
        value: workspace.id,
      }));
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped>
#workspace-list {
  .title {
    color: var(--icon-color);
    font-weight: 500;
    font-size: 13px;
    text-transform: capitalize;
    padding: 16px;
  }

  .wrapper {
    padding: 0px 16px;
  }
}
</style>
