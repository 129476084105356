<template>
  <Sheet :value="value" has-footer width="540px" @input="closeModal">
    <!-- title -->

    <template #title>Alert</template>

    <!-- ... -->

    <template #default>
      <div id="alert-details">
        <Tabs v-model="tab" :tabs="tabs" class="tabs" />
        <div class="q-pa-md">
          <ValidationObserver v-if="tab === 'form'" ref="form">
            <div class="field-wrapper">
              <FormFieldLabel label="Conditions" is-mandatory />

              <div class="options-wrapper">
                <CheckboxField
                  :value="conditions.includes('Checked In')"
                  label="Checked In"
                  class="q-mb-md"
                  @input="updateValue('Checked In')"
                />

                <CheckboxField
                  :value="conditions.includes('Checked Out')"
                  label="Checked Out"
                  class="q-mb-md"
                  @input="updateValue('Checked Out')"
                />

                <CheckboxField
                  :value="conditions.includes('Commented')"
                  label="Commented"
                  class="q-mb-md"
                  @input="updateValue('Commented')"
                />

                <CheckboxField
                  :value="conditions.includes('Email')"
                  label="Email"
                  class="q-mb-md"
                  @input="updateValue('Email')"
                />

                <CheckboxField
                  :value="conditions.includes('Link')"
                  label="Link"
                  class="q-mb-md"
                  @input="updateValue('Link')"
                />

                <CheckboxField
                  :value="conditions.includes('Viewed')"
                  label="Viewed"
                  class="q-mb-md"
                  @input="updateValue('Viewed')"
                />
              </div>

              <FormFieldError v-if="error" :error="error" />

              <ValidationProvider
                v-slot="{ errors }"
                name="Alert To"
                :rules="{ required: true }"
              >
                <MultiSelectField
                  v-model="users"
                  label="alert to"
                  is-mandatory
                  :options="[]"
                  :error="errors[0]"
                  class="q-mb-sm"
                />
              </ValidationProvider>
            </div>
          </ValidationObserver>

          <div v-if="tab === 'history'" id="history">
            <template>
              <div class="entry">
                <div class="header">2 Alerts</div>

                <div class="content">
                  <div class="changes-meta">
                    <div>admin@ezofis.com</div>
                    <BaseIcon name="mdi-circle-small" class="q-mx-xs" />
                    <div>2022-May-06 09:35 PM</div>
                  </div>

                  <div class="change">
                    <BaseIcon name="mdi-circle-small" class="q-mr-sm" />

                    <div>
                      <span class="font-semibold"> "Checkin" </span>
                      alert sent to abc@ezofis.com
                    </div>
                  </div>

                  <div class="change">
                    <BaseIcon name="mdi-circle-small" class="q-mr-sm" />

                    <div>
                      <span class="font-semibold"> "Viewed" </span>
                      alert sent to xyz@ezofis.com
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!-- <div v-else class="no-history">No data found</div> -->
          </div>
        </div>
      </div>
    </template>

    <!-- footer -->

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="closeModal" />

      <BaseButton label="save" />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";

export default {
  name: "Alert",

  components: {
    Sheet,
    ValidationObserver,
    ValidationProvider,
    MultiSelectField,
    FormFieldLabel,
    CheckboxField,
    FormFieldError,
    Tabs,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tab: "form",
      tabs: [
        {
          id: this.$nano.id(),
          label: "form",
          value: "form",
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "history",
        },
      ],
      users: [],
      conditions: [],
      error: "",
    };
  },

  methods: {
    closeModal() {
      this.$emit("close", false);
    },

    updateValue(option) {
      const _value = [...this.conditions];

      if (_value.includes(option)) {
        const optionIdx = _value.indexOf(option);
        _value.splice(optionIdx, 1);
      } else {
        _value.push(option);
      }
      this.conditions = _value;

      // this.$emit("input", _value);
    },
  },
};
</script>

<style lang="scss" scoped>
#alert-details {
  .tabs {
    padding: 0px 16px;
    border-bottom: 1px solid var(--divider-color);
  }
}

#history {
  padding: 16px;

  .no-history {
    font-weight: 500;
    padding: 16px 0px;
    text-align: center;
  }

  .entry {
    padding-left: 24px;
    padding-bottom: 24px;
    position: relative;

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      left: 0px;
      top: 6px;
      background-color: var(--secondary);
      border-radius: 100%;
    }

    &:not(:last-child):after {
      content: "";
      width: 2px;
      height: calc(100% - 6px);
      position: absolute;
      left: 3px;
      top: 14px;
      background-color: var(--hover-bg-color);
    }

    .header {
      color: var(--title-1-color);
      font-weight: 600;
      margin-bottom: 4px;
    }

    .content {
      .changes-meta {
        display: flex;
        align-items: center;
        @extend .text-sm;
        color: var(--icon-color);
        margin-bottom: 16px;
      }

      .change {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        margin-left: -6px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
