<template>
  <Sheet :value="value" has-footer width="540px" @input="closeModal">
    <!-- title -->

    <template #title>Task</template>

    <!-- ... -->

    <template #default>
      <div id="task-details">
        <Tabs v-model="tab" :tabs="tabs" class="tabs" />
        <div class="q-pa-md">
          <ValidationObserver v-if="tab === 'form'" ref="form">
            <div class="field-wrapper">
              <ValidationProvider
                v-slot="{ errors }"
                name="type"
                :rules="{ required: true }"
              >
                <SelectField
                  v-model="type"
                  label="Type"
                  is-mandatory
                  :options="[]"
                  :error="errors[0]"
                  class="q-mb-sm"
                />
              </ValidationProvider>

              <TextAreaField :value="task" label="Task" class="q-mb-sm" />

              <ValidationProvider
                v-slot="{ errors }"
                name="start time"
                :rules="{ required: true }"
              >
                <DateTimeField
                  :value="startTime"
                  label="Start Time"
                  is-mandatory
                  :error="errors[0]"
                  class="q-mb-sm"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="end time"
                :rules="{ required: true }"
              >
                <DateTimeField
                  :value="endTime"
                  label="End Time"
                  is-mandatory
                  :error="errors[0]"
                  class="q-mb-sm"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="users"
                :rules="{ required: true }"
              >
                <MultiSelectField
                  v-model="users"
                  label="users"
                  is-mandatory
                  :options="[]"
                  :error="errors[0]"
                  class="q-mb-sm"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="priority"
                :rules="{ required: true }"
              >
                <SelectField
                  v-model="priority"
                  label="task priority"
                  is-mandatory
                  :options="priorityList"
                  :error="errors[0]"
                  class="q-mb-sm"
                />
              </ValidationProvider>
            </div>
          </ValidationObserver>

          <div v-if="tab === 'history'" id="history">
            <template>
              <div class="entry">
                <div class="header">1 Task Assign</div>

                <div class="content">
                  <div class="changes-meta">
                    <div>admin@ezofis.com</div>
                    <BaseIcon name="mdi-circle-small" class="q-mx-xs" />
                    <div>2022-May-06 09:35 PM</div>
                  </div>

                  <div class="change">
                    <BaseIcon name="mdi-circle-small" class="q-mr-sm" />

                    <div>
                      <span class="font-semibold"> "Assigned" </span>
                      to abc@ezofis.com
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!-- <div v-else class="no-history">No data found</div> -->
          </div>
        </div>
      </div>
    </template>

    <!-- footer -->

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="closeModal" />

      <BaseButton label="save" />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import DateTimeField from "@/components/common/form/date-time-field/DateTimeField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";

export default {
  name: "Task",

  components: {
    Sheet,
    ValidationObserver,
    ValidationProvider,
    TextAreaField,
    SelectField,
    DateTimeField,
    MultiSelectField,
    Tabs,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tab: "form",
      tabs: [
        {
          id: this.$nano.id(),
          label: "form",
          value: "form",
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "history",
        },
      ],
      type: "",
      task: "",
      startTime: "",
      endTime: "",
      users: [],
      priority: "",
      priorityList: [
        {
          id: this.$nano.id(),
          label: "High",
          value: "High",
        },
        {
          id: this.$nano.id(),
          label: "Medium",
          value: "Medium",
        },
        {
          id: this.$nano.id(),
          label: "Low",
          value: "Low",
        },
      ],
    };
  },

  methods: {
    closeModal() {
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#task-details {
  .tabs {
    padding: 0px 16px;
    border-bottom: 1px solid var(--divider-color);
  }
}

#history {
  padding: 16px;

  .no-history {
    font-weight: 500;
    padding: 16px 0px;
    text-align: center;
  }

  .entry {
    padding-left: 24px;
    padding-bottom: 24px;
    position: relative;

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      left: 0px;
      top: 6px;
      background-color: var(--secondary);
      border-radius: 100%;
    }

    &:not(:last-child):after {
      content: "";
      width: 2px;
      height: calc(100% - 6px);
      position: absolute;
      left: 3px;
      top: 14px;
      background-color: var(--hover-bg-color);
    }

    .header {
      color: var(--title-1-color);
      font-weight: 600;
      margin-bottom: 4px;
    }

    .content {
      .changes-meta {
        display: flex;
        align-items: center;
        @extend .text-sm;
        color: var(--icon-color);
        margin-bottom: 16px;
      }

      .change {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        margin-left: -6px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
