<template>
  <BaseActionButton v-tooltip.bottom="'sort'" is-flat icon="mdi-sort" no-border>
    <q-menu transition-show="scale" transition-hide="scale">
      <BaseScrollbar :height="scrollbarHeight" width="240px">
        <!-- criteria -->

        <div class="title-3 q-pa-md">Sort Criteria</div>

        <ListItem
          v-for="column in _columns"
          :key="column.id"
          :icon="
            criteria === column.name
              ? 'eva-radio-button-on'
              : 'eva-radio-button-off'
          "
          :is-selected="criteria === column.name"
          :label="column.label"
          @click="updateCriteria(column.name)"
        />
      </BaseScrollbar>

      <!-- ... -->

      <!-- order -->

      <div class="title-3 q-pa-md">Sort Order</div>

      <ListItem
        :icon="order === 'ASC' ? 'eva-radio-button-on' : 'eva-radio-button-off'"
        :is-selected="order === 'ASC'"
        label="Ascending"
        @click="updateOrder('ASC')"
      />

      <ListItem
        :icon="
          order === 'DESC' ? 'eva-radio-button-on' : 'eva-radio-button-off'
        "
        :is-selected="order === 'DESC'"
        label="Descending"
        class="q-mb-sm"
        @click="updateOrder('DESC')"
      />

      <!-- ... -->
    </q-menu>
  </BaseActionButton>
</template>

<script>
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "SortItems",

  components: { ListItem },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    criteria: {
      type: String,
      required: true,
    },

    order: {
      type: String,
      required: true,
    },
  },

  computed: {
    _columns() {
      return [
        {
          id: this.$nano.id(),
          label: "None",
          name: "",
        },
        ...this.columns.filter((column) => column.isSortable),
      ];
    },

    scrollbarHeight() {
      const height = this._columns.length * 54;
      return height > 216 ? "224px" : `${height}px`;
    },
  },

  methods: {
    updateCriteria(criteria) {
      this.$emit("update:criteria", criteria);
    },

    updateOrder(order) {
      this.$emit("update:order", order);
    },
  },
};
</script>

<style lang="scss" scoped></style>
