<template>
  <Confirm
    :value="value"
    icon="mdi-pin-outline"
    icon-color="primary"
    @input="handleInput"
  >
    <template #title>UnPin</template>

    <template #default>
      <div class="q-py-md">
        <p>
          Are you sure you want to unpin the
          <b>"{{ label }}"</b>. If you unpin, you will not be able to use this
          in the dashboard.
        </p>
      </div>
    </template>

    <template #footer>
      <BaseButton
        is-flat
        label="no"
        class="q-mr-sm"
        color="primary"
        @click="handleNo"
      />
      <BaseButton label="yes" color="primary" @click="deleteFilter" />
    </template>
  </Confirm>
</template>

<script>
import Confirm from "@/components/common/popup/Confirm.vue";
import { dashboard } from "@/api/factory.js";

export default {
  name: "UnpinDashboard",

  components: { Confirm },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    deleteData: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },

    handleNo() {
      this.$emit("input", false);
    },

    async deleteFilter() {
      const { error } = await dashboard.deleteFilter(
        this.deleteData.id,
        this.deleteData
      );
      if (error) {
        this.$alert.warning("error");
        return;
      }

      this.$emit("input", false);
      this.$emit("delete");
      this.$alert.success("Unpinned Successfully");
    },
  },
};
</script>

<style lang="scss" scoped></style>
