<template>
  <Modal
    :value="value"
    :is-header="false"
    :has-footer="true"
    width="60vw"
    height="80vh"
    @input="$emit('input')"
  >
    <!-- <template #title>
      <template v-if="summarizeView">
        Summarize :
        <span class="text-primary">{{ summarizingData.title }}</span>
      </template>
      <template v-else>
        <div>summarize Data</div>
      </template>
    </template> -->

    <template #default>
      <template v-if="summarizeView">
        <div class="footer-modal q-pa-sm">
          <BaseIcon name="mdi-creation" color="secondary" />
          <span class="footer-text">Recommended Action:</span>
          {{ summarizingData.description }}
        </div>
        <div class="q-pt-md">
          <BaseScrollbar height="calc(100vh - 320px)">
            <table>
              <tbody>
                <template v-for="(keyValue, key) in summarizingData.data">
                  <template v-if="typeof keyValue === 'string'">
                    <tr :key="key">
                      <td class="tablekey">{{ key }}</td>
                      <td class="tablevalue">{{ keyValue }}</td>
                    </tr>
                  </template>

                  <!--object-->
                  <template v-else-if="typeof keyValue === 'object'">
                    <template v-if="Array.isArray(keyValue)">
                      <tr :key="key">
                        <td colspan="2" class="tablekey" style="color: #643094">
                          {{ key }}
                        </td>
                      </tr>
                      <tr :key="key">
                        <td colspan="2">
                          <table class="sub-table">
                            <thead>
                              <tr>
                                <th
                                  v-for="(field_item, field_key) in keyValue[0]"
                                  :key="field_key"
                                  class="tablevalue"
                                  style="color: #00bcd4"
                                >
                                  {{ field_key }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(field_item, field_key) in keyValue"
                                :key="field_key"
                              >
                                <td
                                  v-for="(row_value, row_key) in field_item"
                                  :key="row_key"
                                  class="tablevalue"
                                >
                                  {{ row_value }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr :key="key">
                        <td colspan="2" class="tablekey" style="color: #643094">
                          {{ key }}
                        </td>
                      </tr>
                      <template v-for="(objectvalue, objectkey) in keyValue">
                        <template v-if="typeof objectvalue === 'string'">
                          <tr :key="objectkey">
                            <td class="tablekey">{{ objectkey }}</td>
                            <td class="tablevalue">{{ objectvalue }}</td>
                          </tr>
                        </template>

                        <!--object in object--->

                        <template v-else-if="typeof objectvalue === 'object'">
                          <tr :key="objectkey">
                            <td
                              colspan="2"
                              class="tablekey"
                              style="color: #f49d3a"
                            >
                              {{ objectkey }}
                            </td>
                          </tr>
                          <template
                            v-for="(
                              objectvalueobject, objectkeyobject
                            ) in objectvalue"
                          >
                            <template
                              v-if="typeof objectvalueobject === 'string'"
                            >
                              <tr :key="objectkeyobject">
                                <td class="tablekey">{{ objectkeyobject }}</td>
                                <td class="tablevalue">
                                  {{ objectvalueobject }}
                                </td>
                              </tr>
                            </template>
                          </template>
                        </template>

                        <!--object in array--->

                        <template v-else-if="typeof objectvalue === 'array'">
                          <tr :key="objectkey">
                            <td colspan="2">{{ objectkey }}</td>
                          </tr>
                          <template
                            v-for="(
                              objectvaluearray, objectkeyarray
                            ) in objectvalue"
                          >
                            <template
                              v-if="typeof objectvalueobject === 'string'"
                            >
                              <tr :key="objectkeyarray">
                                <td class="tablekey">{{ objectkeyarray }}</td>
                                <td class="tablevalue">
                                  {{ objectvaluearray }}
                                </td>
                              </tr>
                            </template>
                          </template>
                        </template>
                      </template>
                    </template>
                  </template>

                  <!--array-->
                </template>
              </tbody>
            </table>
          </BaseScrollbar>
        </div>
      </template>
      <template v-else>
        <div class="htmlElement" v-html="summarizingData"></div>
      </template>
    </template>

    <template #footer>
      <BaseButton label="Close" @click="$emit('input', false)"
    /></template>
  </Modal>
</template>

<script>
import Modal from "@/components/common/popup/Modal.vue";
import { workflow, repository } from "@/api/factory.js";

export default {
  name: "Summarize",

  components: {
    Modal,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    repositoryId: {
      type: Number,
      default: 0,
    },

    itemid: {
      type: Number,
      default: 0,
    },
    transactionId: {
      type: Number,
      default: 0,
    },
    workflowId: {
      type: Number,
      default: 0,
    },
    processId: {
      type: Number,
      default: 0,
    },
    page: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      summarizingData: "",
      summarizeView: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          if (this.page === "REPOSITORY") {
            this.documentSummarize();
          } else if (this.page === "WORKFLOW") {
            this.workflowRecommendation();
          }
        }
      },
    },
  },

  methods: {
    async documentSummarize() {
      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await repository.getSummary(
        this.repositoryId,
        this.itemid
      );

      this.$store.commit("hideLoadingBarSave");
      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        try {
          this.summarizingData = JSON.parse(payload.output);
          this.summarizeView = true;
        } catch (e) {
          console.log(e);
          this.summarizeView = false;
          this.summarizingData = payload.output
            .replace(
              new RegExp("<title>", "g"),
              '<title class="heading" style="display:block">'
            )
            .replace(new RegExp("<h1>", "g"), '<h6 class="heading">')
            .replace(new RegExp("<h2>", "g"), '<h6 class="heading2">')
            .replace(
              new RegExp("<table>", "g"),
              '<table class="custom-table-style">'
            )
            .replace(new RegExp("<p>", "g"), '<p style="margin-top:20px">');
        }
      }
    },

    async workflowRecommendation() {
      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await workflow.getWorkflowRecommendation(
        this.workflowId,
        this.processId,
        this.transactionId
      );
      this.$store.commit("hideLoadingBarSave");
      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) {
        try {
          this.summarizingData = JSON.parse(payload.output);
          this.summarizeView = true;
        } catch (e) {
          console.log(e);
          this.summarizeView = false;
          this.summarizingData = payload
            .replace(
              new RegExp("<title>", "g"),
              '<title class="heading" style="display:block">'
            )
            .replace(new RegExp("<h1>", "g"), '<h6 class="heading">')
            .replace(new RegExp("<h2>", "g"), '<h6 class="heading2">')
            .replace(
              new RegExp("<table>", "g"),
              '<table class="custom-table-style">'
            )
            .replace(new RegExp("<p>", "g"), '<p style="margin-top:20px">');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#modal {
  table {
    width: 100%;
    border: 1px solid var(--divider-color);
    border-radius: 10px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      padding: 8px;
      font-weight: 500;
    }

    th {
      border-bottom: 1px solid var(--divider-color);
    }

    tr:not(:first-child) td {
      border-top: 1px solid var(--divider-color); /* Add top border to the first row */
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid var(--divider-color); /* Add top border to the first row */
    }

    .tablekey {
      color: #00bcd4;
    }

    .sub-table {
      th {
        border-bottom: none;
      }
    }
  }

  .footer-modal {
    padding-top: 10px;
    border-radius: 10px;
    border: 1px solid var(--divider-color);
    line-height: 1.6;
  }
  .footer-text {
    color: #d40083;
    font-weight: bold;
    padding-left: 6px;
  }
  .htmlElement {
    h6 {
      /* CSS for all <h6> tags inside .htmlElement */
      color: #ff0000; /* Example red color */
      font-weight: bold;
      /* Add other styles as needed */
    }
  }
}
</style>
<style>
.custom-table-style {
  border: 1px solid var(--divider-color);
  border-radius: 10px;
  width: 100%;
}

.custom-table-style tr {
  height: 36px;
}

.custom-table-style th {
  color: var(--secondary);
  text-align: left;
}

.custom-table-style th,
.custom-table-style td {
  padding: 8px;
  font-weight: 500;
}

.custom-table-style tr:not(:first-child) td {
  border-top: 1px solid var(--divider-color);
}

.custom-table-style th:not(:last-child),
.custom-table-style td:not(:last-child) {
  border-right: 1px solid var(--divider-color);
}

.custom-table-style .tablekey {
  color: #00bcd4;
}
.heading {
  margin-top: 0px;
  margin-bottom: 2px;
  font-weight: bold;
  border-bottom: 1px solid var(--divider-color);
  line-height: 3rem;
}
.heading2 {
  margin-top: 20px;
  margin-bottom: 4px;
  font-weight: bold;
}
</style>
